import { sortMessagesAndRemoveDuplicates } from '@/lib/utils';
import { useGetNewMessages, useGetReadMessages, useMarkAsRead, useSendMessage } from '@/services/api/messages';
import useInView from '@/services/hooks/useInView';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import AppState from '@/services/state/AppState';
import PersistentStorage from '@/services/storage';
import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { Group, Stack } from '@mantine/core';
import { Loader2 } from 'lucide-react';
import { ArrowDownIcon, ArrowLeftIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ChatInput from './ChatInput';
import MessagesList, { MessagesListRef } from './MessagesList';

const addReadMessagesLocal = async (messages: any) => {
  if (!messages || messages.length === 0) return;
  const session = await PersistentStorage.getItem();
  const sortedAndUnique = session.chat.messages
    ? sortMessagesAndRemoveDuplicates([...messages, ...session.chat.messages])
    : sortMessagesAndRemoveDuplicates(messages);
  await PersistentStorage.updateItem(session.email, {
    chat: { ...session.chat, messages: sortedAndUnique },
  });
};

const Chat = ({ styles }) => {
  const location = useLocation();
  const newMessageSpected = location.state.newMessages;
  const messagesListTopRef = useRef<MessagesListRef>(null);
  // get infor from router loader
  const lastMsgId = useLoaderData() as string;

  const isMobile = useCheckMobileScreen();
  const appState = AppState.useContainer();
  const messagesPerRequest = 20;

  const [readMessages, setReadMessages] = useState<any>([]);
  const [newMessages, setNewMessages] = useState<any>([]);
  const [showNewMessages, setShowNewMessages] = useState<boolean>(newMessageSpected);
  const [newMessagesLoading, setNewMessagesLoading] = useState<boolean>(false);
  const [lastMessageId, setLastMessageId] = useState<string | null>(lastMsgId);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [sendingOneMsgAtm, setSendingOneMsgAtm] = useState<boolean>(false);

  const messagesTopRefIsInView = useInView(messagesListTopRef?.current?.messagesTopRef);
  const isBottomInView = useInView(messagesListTopRef?.current?.messagesBottomRef);

  const { data: readMessagesPure, isLoading: readMessageLoading } = useGetReadMessages(messagesPerRequest, Math.floor(readMessages.length / messagesPerRequest) + 1, { enabled: firstLoad });
  const { data: newMessagesServer, isLoading } = useGetNewMessages(lastMessageId, { enabled: !!lastMessageId });
  const sendMessageServer = useSendMessage();
  const markAsReadServer = useMarkAsRead();

  const loadFromLocal = async (from: number = 0, to: number = messagesPerRequest): Promise<ChatMessage[]> => {
    const localMessages = await PersistentStorage.getMessagesLocal();
    const messagesSubset = localMessages.slice(from, to);
    return messagesSubset;
  };

  const addReadMessagesState = (messages: ChatMessage[]) => {
    const sortedAndUnique = sortMessagesAndRemoveDuplicates([...readMessages, ...messages]);
    setReadMessages(sortedAndUnique);
  };

  // Retrieve local stored chat messages
  useEffect(() => {
    (async () => {
      const localMessages = await loadFromLocal();
      if (localMessages.length) {
        setReadMessages(localMessages);
        setLastMessageId(localMessages[0].id);
        if (showNewMessages) setNewMessagesLoading(true);
      }
      setFirstLoad(true);
    })();
  }, []);

  // Fetch initial batch of messages from the server
  useEffect(() => {
    if (firstLoad && readMessagesPure) {
      if (readMessagesPure.messages.length > readMessages.length) {
        addReadMessagesState(readMessagesPure.messages);
        addReadMessagesLocal(readMessagesPure.messages);

        setLastMessageId(readMessagesPure.messages[0].id);
        if (showNewMessages) setNewMessagesLoading(true);
      } else if (!readMessagesPure.messages.length && !readMessages.length) {
        setLastMessageId('all');
      }
    }
  }, [readMessagesPure, firstLoad]);

  // Update new messages from server
  useEffect(() => {
    if (newMessagesServer) {
      const excludeManagedUser = newMessagesServer.filter(msg => msg.createdBy !== MESSAGE_AUTHOR.MANAGED_USER);
      if (excludeManagedUser.length) {
        if (isBottomInView) {
          // Mark all messages as read and
          markAsReadServer.mutate({});
        }

        if (showNewMessages) {
          setNewMessages(excludeManagedUser);
          setShowNewMessages(false);
          setNewMessagesLoading(false);
        } else {
          // Add the new messages to state
          addReadMessagesState([...newMessages, ...excludeManagedUser]);
          setNewMessages([]);
        }

        // Use the last message as the anchor for the newest message
        setLastMessageId(excludeManagedUser[0].id);

        // Add the new messages to state and local storage
        addReadMessagesLocal(excludeManagedUser);
      }
    }
  }, [newMessagesServer]);

  // Try loading past messages from local storage first before fetching from server
  useEffect(() => {
    if (messagesTopRefIsInView && firstLoad) {
      (async () => {
        const localMessages = await PersistentStorage.getMessagesLocal();

        if (localMessages.length > readMessages.length) {
          const messagesSubset = await loadFromLocal(readMessages.length, readMessages.length + messagesPerRequest);
          addReadMessagesState(messagesSubset);
        } else {
          addReadMessagesState(readMessagesPure?.messages || []);
        }
      })();
    }
  }, [messagesTopRefIsInView, readMessagesPure]);

  const moveMessagesToRead = (sentMessage: ChatMessage, messagesToMove?: ChatMessage[]) => {
    if (!messagesToMove) messagesToMove = [...readMessages];
    // Add sent msg
    messagesToMove.unshift(sentMessage);

    if (newMessages.length) {
      messagesToMove = sortMessagesAndRemoveDuplicates([...messagesToMove, ...newMessages]);
      setNewMessages([]);
    }

    // Add the messages to state and local storage
    setReadMessages(messagesToMove);
    addReadMessagesLocal(messagesToMove);
  };

  /**
   * Handles the submission of a chat message.
   *
   * This function performs the following steps:
   * 1. Checks if a message is provided and is not empty after trimming whitespace.
   * 2. Temporarily sets a flag to indicate a message is being sent.
   * 3. Creates a temporary message object to display in the message list.
   * 4. Updates the message lists by moving new messages to read messages if any exist.
   * 5. Adds the temporary message to the read messages list.
   * 6. Sends the message using the `sendMessage` mutation.
   * 7. Removes the temporary message from the read messages list.
   * 8. Moves the newly sent message to the read messages list.
   * 9. Marks the chat as read using the `markAsRead` mutation.
   *
   * @param {string} [message] - The message text to be sent. If not provided or empty, the function will not proceed.
   * @returns {Promise<void>} - A promise that resolves when the message handling is complete.
   * @throws {Error} - Logs any errors that occur during message handling.
   */
  const handleSubmitMessage = async (message?: string) => {
    try {
      // Check if the message is not null, undefined, or an empty string after trimming whitespace (includes "\n")
      if (!sendingOneMsgAtm && message && message.trim() !== '') {
        // Set flag to prevent loss/inconsistency messages
        setSendingOneMsgAtm(true);

        // Set temp message object to display in message list
        const tempMsg = {
          'id': null,
          'text': `${message}`,
          'createdAt': '',
          'updatedAt': '',
          'sentAt': new Date().toISOString(),
          'readAt': 'null',
          'createdBy': 'MANAGED_USER',
          'createdByIdentity': 'not-defined',
          'metadata': null,
          'type': 'TEXT',
          'authId': '',
        };

        let tempReadMessages = [...readMessages];
        if (newMessages.length) {
          setNewMessages([]);
          tempReadMessages = [...newMessages, ...readMessages];
        }
        // Add temp msg
        tempReadMessages.unshift(tempMsg);
        setReadMessages(tempReadMessages);

        // Send message
        const sentMessage = await sendMessageServer.mutateAsync({
          text: message,
          createdBy: MESSAGE_AUTHOR.MANAGED_USER,
          createdByIdentity: appState.signupAndLogin.email ? appState.signupAndLogin.email : 'not-defined',
        });
        // Remove temp msg
        tempReadMessages.shift();

        // Move the new message to readMessages
        moveMessagesToRead(sentMessage, tempReadMessages);
        setSendingOneMsgAtm(false);
      }
      markAsReadServer.mutate({});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack
      style={{
        // position: 'absolute',
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        ...styles,
      }}
      gap={0}
      bg={'#F4F1EB'}
    >
      <MessagesList
        showNewMessages={showNewMessages}
        newMessagesLoading={newMessagesLoading}
        newMessages={newMessages}
        readMessages={readMessages}
        isMobile={isMobile}
        isLoading={readMessageLoading}
        isFetchingNextPage={readMessageLoading}
        ref={messagesListTopRef}
      />
      <Group style={{ flexShrink: 0 }} w={'100%'} justify='center'>
        <ChatInput
          onSend={handleSubmitMessage}
          moveMessagesToRead={moveMessagesToRead}
          sendingOneMsgAtm={sendingOneMsgAtm}
          authId={null}
          isMobile={isMobile}
        />
      </Group>
    </Stack>
  );
};

export default Chat;
