import MailberryLogo from '@/assets/icons/MailberryLogo';
import ReloadTokens, { ReloadTokenType } from '@/services/state/ReloadTokensState';
import { Button } from '@mantine/core';
import { BarChartIcon } from '@radix-ui/react-icons';
import { StepType } from '@reactour/tour';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRestartDemo } from '../api/demo/account';

const mbgreen = '#70c56e';

export const steps: StepType[] = [
  { // step 0
    position: 'center',
    styles: {
      close: base => ({ ...base, display: 'none' }),
      popover: base => ({ ...base, width: '400', borderRadius: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }),
    },
    selector: '.tour-wrapper',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center' }}>
        <p style={{ margin: '0', fontSize: '18px', fontWeight: 'bold' }}>Welcome to your Mailberry demo!</p>
        <p style={{ margin: '0' }}>See how we create & manage your emails in just a few minutes.</p>
        <br />
        <Button my={4} bg={'#70C56E'} mx={'auto'} onClick={() => setCurrentStep(1)}>Let's get started</Button>
      </div>
    ),
    stepInteraction: false,
  },
  { // step 1
    stepInteraction: true,
    selector: '.tour-first-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center' }}>
        <p style={{ margin: '0' }}>Your next email is fresh out of the oven!</p>
        <p style={{ margin: '0' }}>
          Click <span style={{ color: mbgreen, fontWeight: 700 }}>'View'</span> to give the email a <span style={{ fontWeight: 700 }}>final look before it goes out.</span>
        </p>
      </div>
    ),
    position: (pos, w) => {
      let x: number, y: number;
      if (pos.windowWidth < 736) {
        x = (pos.windowWidth / 2) - (pos.width / 2);
        y = 210;
      } else {
        x = (pos.windowWidth / 2) + 100;
        y = 270;
      }
      return [x, y];
    },
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', width: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', ...doArrow(1) });
      },
    },
  },
  { // step 2
    stepInteraction: true,
    selector: '.tour-second-step',
    styles: {
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', width: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }); // ...doArrow(2)
      },
      close: () => ({ display: 'none' }),
    },
    position: (pos, a) => {
      let x: number, y: number;
      if (pos.windowWidth < 768) {
        x = (pos.windowWidth / 2) - (pos.width / 2);
        y = 50;
      } else {
        x = (pos.windowWidth / 2) - (pos.width / 2);
        y = 100;
      }
      return [x, y];
    },
    content: ({ setCurrentStep, currentStep }) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <p style={{ margin: '0' }}>
            <span style={{ color: mbgreen, fontWeight: 700 }}>Scroll</span> to see how Mailberry craft emails personalized to your brand.
          </p>
          <br />
          <p style={{ margin: '0', fontWeight: 700 }}>Nike Cortez Campaign Preview</p>
          <p style={{ margin: '0' }}>
            <span style={{ color: mbgreen }}>✓</span> Copy written for you,
          </p>
          <p style={{ margin: '0' }}>
            <span style={{ color: mbgreen }}>✓</span> Professional design,
          </p>
          <p style={{ margin: '0' }}>
            <span style={{ color: mbgreen }}>✓</span> Ready to send.
          </p>
        </div>
      );
    },
  },
  {
    selector: '.tour-review-input',
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        let arrowIndex: number;
        if (state.targetRect.windowWidth < 736) {
          arrowIndex = 3;
        } else {
          arrowIndex = 2;
        }
        return { ...base, borderRadius: '12px', width: '400px', ...doArrow(arrowIndex) };
      },
    },
    position: (pos, a) => {
      let x: number, y: number;
      if (pos.windowWidth < 768) {
        x = (pos.windowWidth / 2) - (pos.width / 2);
        y = pos.windowHeight - 300;
      } else {
        x = pos.windowWidth - 450;
        y = 300;
      }
      return [x, y];
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center' }}>
        <p style={{ margin: '0' }}>Hmm... the opening sentence could use a little more punch.</p>
        <p style={{ margin: '0' }}>
          Let's ask Mailberry to <span style={{ fontWeight: 700 }}>spice it up</span>. Simply:
        </p>
        <br />
        <p style={{ margin: '0' }}>1. Type your suggestion below.</p>
        <p style={{ margin: '0' }}>
          2. Hit <span style={{ color: mbgreen, fontWeight: 700 }}>send</span>.
        </p>
      </div>
    ),
  },
  { // step 3
    stepInteraction: true,
    selector: '.tour-third-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center' }}>
        <p style={{ margin: '0', fontWeight: 700 }}>Leave the heavy lifting to us.</p>
        <br />
        <p style={{ margin: '0' }}>
          Hit <span style={{ color: mbgreen, fontWeight: 700 }}>'Request changes'</span> and relax.
        </p>
        <p style={{ margin: '0' }}>Your dedicated email team will:</p>
        <br />
        <p style={{ margin: '0' }}>
          <span style={{ color: mbgreen }}>✓</span> Perfect you copy,
        </p>
        <p style={{ margin: '0' }}>
          <span style={{ color: mbgreen }}>✓</span> Polish the design,
        </p>
        <p style={{ margin: '0' }}>
          <span style={{ color: mbgreen }}>✓</span> Prepare for sending.
        </p>
      </div>
    ),
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', width: '400px', ...doArrow(4) });
      },
    },
  },
  { // step 4
    // stepInteraction: false,
    selector: '.tour-fourth-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center' }}>
        <p style={{ margin: '0', fontWeight: 700 }}>Your email has been perfected!</p>
        <br />
        <p style={{ margin: '0' }}>Mailberry has worked its magic on you email.</p>
        <p style={{ margin: '0' }}>
          Click <span style={{ color: mbgreen }}>'View'</span> to see the enhanced version ready for sending.
        </p>
      </div>
    ),
    position: pos => {
      let x: number, y: number;
      if (pos.windowWidth < 768) {
        x = (pos.windowWidth / 2) - (pos.width / 2);
        y = 180;
      } else {
        x = (pos.windowWidth / 2) + 50;
        y = 160;
      }
      return [x, y];
    },
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(1) });
      },
    },
  },
  { // step 5
    stepInteraction: true,
    selector: '.tour-second-step',
    position: 'center',
    styles: {
      popover: (base, state) => {
        return ({
          ...base,
          borderRadius: '12px',
          width: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        });
      },
      close: () => ({ display: 'none' }),
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center' }}>
        <p style={{ margin: '0', fontWeight: 700 }}>Review your enhanced email</p>
        <br />
        <p style={{ margin: '0' }}>- Purple highlight shows our improvement</p>
        <p style={{ margin: '0' }}>
          - Scroll down for a <span style={{ fontWeight: 700 }}>quick final check</span>
        </p>
        <br />
        <Button mt={4} bg={'#70C56E'} onClick={() => setCurrentStep(currentStep + 1)}>Continue to approval</Button>
      </div>
    ),
  },
  { // step 6
    selector: '.tour-fifth-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center' }}>
        <p style={{ margin: '0', fontWeight: 700 }}>Ready to launch!</p>
        <br />
        <p style={{ margin: '0' }}>
          One click on <span style={{ color: mbgreen, fontWeight: 700 }}>'Approve email'</span> and this prefectly-crafted campaign lands in your inbox!
        </p>
      </div>
    ),
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', width: '400px', ...doArrow(5) });
      },
    },
  },
  { // step 8
    stepInteraction: false,
    position: pos => {
      let x: number, y: number;
      y = 60;
      x = (pos.windowWidth / 2) - (pos.width / 2);
      return [x, y];
    },
    selector: '.tour-chat',
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({
          ...base,
          borderRadius: '12px',
          width: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }); // ...doArrow(5)
      },
    },
    content: ({ setCurrentStep, setIsOpen, currentStep }) => {
      const navigate = useNavigate();

      return (
        <div style={{ textAlign: 'center' }}>
          <p style={{ margin: '0', fontWeight: 700 }}>Meet your email marketing team! 👋</p>
          <br />
          <p style={{ margin: '0' }}>Have questions about performance or ideas for your next campaign?</p>
          <br />
          <p style={{ margin: '0' }}>
            <span style={{ fontWeight: 700 }}>We are here 24/7</span>- just tap <span style={{ color: mbgreen, fontWeight: 700 }}>'Chat'</span> to connect with your dedicated team.
          </p>
          <br />
          <Button
            bg={mbgreen}
            onClick={() => {
              setCurrentStep(currentStep + 1);
              navigate('/demo/reports');
            }}
          >
            Next
          </Button>
        </div>
      );
    },
  },
  { // step 9
    stepInteraction: false,
    selector: '.tour-reports',
    position: pos => {
      let x: number, y: number;
      y = pos.windowHeight - 500;
      x = (pos.windowWidth / 2) - (pos.width / 2);
      return [x, y];
    },
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        let arrowIndex: number;
        if (state.targetRect.windowWidth < 736) {
          arrowIndex = 8;
        } else {
          arrowIndex = 6;
        }
        return ({
          ...base,
          borderRadius: '12px',
          width: '400px',
          backgroundColor: '#f5f5f1',
          // ...doArrow(arrowIndex)
        });
      },
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <BarChartIcon />
        <p style={{ margin: '0', fontWeight: 700, fontSize: 18 }}>Track your email marketing success!</p>
        <br />
        <p style={{ margin: '0' }}>See how your campaigns drive results:</p>
        <br />
        <p style={{ margin: '0' }}>- Performance trends,</p>
        <p style={{ margin: '0' }}>- Engagement metrics,</p>
        <p style={{ margin: '0' }}>- Revenue impact.</p>
        <br />
        <p style={{ margin: '0' }}>
          Tap <span style={{ color: mbgreen, fontWeight: 700 }}>'Reports'</span> to explore the impact of your campaigns.
        </p>
        <br />
        <Button bg={mbgreen} onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
      </div>
    ),
  },
  { // step 12
    stepInteraction: false,
    position: 'center',
    selector: '.tour-wrapper',
    content: ({ setCurrentStep, setIsOpen }) => {
      const navigate = useNavigate();
      const restartDemo = useRestartDemo();
      const { setReloadToken } = ReloadTokens.useContainer();
      const resetDemo = () => {
        restartDemo.mutateAsync({})
          .then(() => {
            setReloadToken(ReloadTokenType.Review);
            localStorage.removeItem('tourCompleted');
            setCurrentStep(0);
            setIsOpen(true);
            navigate('/demo');
          });
      };
      return (
        <div style={{ textAlign: 'center', borderRadius: '12px' }}>
          <MailberryLogo />
          <p style={{ margin: '0', fontWeight: 700, fontSize: 18 }}>Join 100+ brands growing with Mailberry</p>
          <p style={{ margin: '0', fontSize: 14 }}>Get a custom revenue growth plan in 30 minutes</p>
          <br />
          <Button
            radius={'xl'}
            bg={'#70C56E'}
            onClick={() => {
              setIsOpen(false);
              window.open('https://mailberry.ai/book-a-call/', '_blank');
              navigate('/demo');
            }}
          >
            Book your FREE strategy call
          </Button>
          <br />
          <br />
          <Button
            variant='transparent'
            onClick={resetDemo}
            style={{ textDecoration: 'underline', fontSize: 14 }}
          >
            Check out demo again
          </Button>
        </div>
      );
    },
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px' });
      },
    },
    actionAfter: () => localStorage.setItem('tourCompleted', '1'),
  },
];

function doArrow(step: number) {
  const base = {
    [`--rtp-arrow-border-left`]: `10px solid transparent`,
    [`--rtp-arrow-border-right`]: `10px solid transparent`,
  };
  if (step === 1) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '30px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 2) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '190px',
      [`--rtp-arrow-top`]: '-20px',
      [`--rtp-arrow-border-bottom`]: `24px solid white`,
    };
  } else if (step === 3) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '190px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 4) {
    return {
      ...base,
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 5) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '20px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 6) {
    return {
      ...base,
      [`--rtp-arrow-left`]: '20px',
      [`--rtp-arrow-top`]: '-20px',
      [`--rtp-arrow-border-bottom`]: `24px solid white`,
    };
  } else if (step === 7) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '20px',
      [`--rtp-arrow-top`]: '-20px',
      [`--rtp-arrow-border-bottom`]: `24px solid white`,
    };
  } else if (step === 8) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '80px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 9) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '80px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  }
}
