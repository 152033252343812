import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import AppState from '@/services/state/AppState';
import { Capacitor } from '@capacitor/core';
import { Box } from '@mantine/core';
import { useTour } from '@reactour/tour';
import React, { useEffect } from 'react';
import DemoOverview from './Overview/Demo';

export default function DemoDashboard() {
  return (
    <Box h={'100%'} w={'100%'} pos='relative'>
      <MailberryLoader visible={false} />
      <DemoOverview />
    </Box>
  );
}
