import { useInstallIntegration } from '@/services/api/shopify';
import { Container, LoadingOverlay } from '@mantine/core';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const InstallShopifyApp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hmac = searchParams.get('hmac');
  const host = searchParams.get('host');
  const session = searchParams.get('session');
  const shop = searchParams.get('shop');
  const timestamp = searchParams.get('timestamp');

  const installIntegration = useInstallIntegration();

  useEffect(() => {
    let queryParams: Record<string, string> = { hmac, host, shop, timestamp };
    if (session) {
      queryParams = { hmac, host, session, shop, timestamp };
    }

    installIntegration.mutateAsync(queryParams as any)
      .then(r => {
        console.log(1, r);
        if (typeof r !== 'string') {
          // already installed
          return navigate('/settings/shopify');
        }
        window.open(r, '_self');
      })
      .catch(e => {
        console.error(e);
        navigate('/settings/shopify');
      });
  }, []);

  return (
    <>
      <Container fluid style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column', flexWrap: 'wrap' }}>
        <LoadingOverlay visible />
      </Container>
    </>
  );
};

export default InstallShopifyApp;
