import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, useMailberryInfiniteQuery, useMailberryMutate, useMailberryQuery } from './base';

type SendMessage = {
  createdBy: MESSAGE_AUTHOR;
  createdByIdentity: string;
  text: string;
};
type GetReadMessages = {
  totalPages: number;
  total: number;
  messages: ChatMessage[];
  page: number;
};

export function useSendMessageAdmin(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<ChatMessage, any>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/admin/messages`, userData);
    },
    options: { onSuccess, onError },
    reloadTokenType: ReloadTokenType.Chat,
  });
}

export function useGetReadMessagesAdminInfiniteQuery(limit: number, authId: string, options?: UseInfiniteQueryOptions) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryInfiniteQuery<GetReadMessages>({
    f: (accessToken, pageParam = 1) => {
      return genericAuthRequest(accessToken, 'get', '/admin/messages/read', { limit, page: pageParam, authId });
    },
    queryKey: [reloadTokens[ReloadTokenType.Chat], authId],
    options: {
      ...options,
    },
  });
}

// export function useGetUnreadMessagesAdmin(authId: string) {
//   return useMailberryQuery<ChatMessage[]>({
//     f: accessToken => {
//       return genericAuthRequest(accessToken, 'get', `/admin/messages/unread`, { authId });
//     },
//     // queryKey: [reloadTokens[ReloadTokenType.Chat]],
//     options: { refetchInterval: 1000 },
//   });
// }

export function useGetAllLastMessagesAdminInfiniteQuery(limit: number, options?: UseInfiniteQueryOptions) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryInfiniteQuery<any>({
    f: (accessToken, pageParam = 1) => {
      return genericAuthRequest(accessToken, 'get', '/admin/messages/all-last', { limit, page: pageParam });
    },
    queryKey: [reloadTokens[ReloadTokenType.Chat]],
    options: {
      ...options,
      refetchInterval: 5000,
    },
  });
}

export function useGetNewMessagesAdmin(authId: string, lastMessageId: string | null, rand: number, options?) {
  return useMailberryQuery<ChatMessage[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/admin/messages/new`, { authId: authId, lastMessageId });
    },
    queryKey: [authId, rand],
    options: { refetchInterval: 3000, cacheTime: 0, staleTime: 0, ...options },
  });
}

export function useMarkAsReadAdmin() {
  return useMailberryMutate<{}, {}>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/admin/messages/mark-as-read`, userData);
    },
    reloadTokenType: ReloadTokenType.Chat,
  });
}
