import XLSX from 'xlsx';

export function formatTime(time: string) {
  let dateObject = new Date(time);

  let hours: number | string = dateObject.getHours();
  let minutes: number | string = dateObject.getMinutes();

  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  return hours + ':' + minutes;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  const isToday = date.toDateString() === now.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else if (isYesterday) {
    return 'Yesterday';
  } else {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }
}

export function formatTimeAndDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleDateString(undefined, options);
}

export function filterObjectsById(firstArray: any[], secondArray: any[] | undefined) {
  // Extract ids from the first array
  const ids = firstArray.map(obj => obj.id);

  // Filter out objects from the second array whose id exists in the first array
  if (!secondArray) return [];
  const filteredArray = secondArray.filter(obj => !ids.includes(obj.id));
  return filteredArray;
}

export const sortMessagesAndRemoveDuplicates = (messages: any[]) => {
  const sorted = messages.sort((a: any, b: any) => {
    return new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime();
  });
  const unique = sorted.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  return unique;
};

export function parseStyleString(styleString: string) {
  const styleDict: Record<string, string> = {};

  // Split the style string by semicolons to separate individual style declarations
  const declarations = styleString.split(';');

  // Iterate through each declaration
  declarations.forEach(declaration => {
    // Trim any leading or trailing whitespace from the declaration
    const trimmedDeclaration = declaration.trim();

    // Check if the declaration is not empty
    if (trimmedDeclaration) {
      // Split each declaration by colon to separate property and value
      const parts = trimmedDeclaration.split(':');

      // If there are more than 1 parts, then we have both property and value
      if (parts.length > 1) {
        const property = toCamelCase(parts[0].trim());
        const value = parts.slice(1).join(':').trim();
        // remove the important flag
        if (value.includes('!important')) {
          styleDict[property] = value.replace('!important', '').trim();
        } else {
          styleDict[property] = value;
        }
      } else {
        // If there's only one part, consider it as the property and set value to empty string
        const property = toCamelCase(parts[0].trim());
        styleDict[property] = '';
      }
    }
  });

  return styleDict;
}

// Function to convert kebab-case to camelCase
export function toCamelCase(str: string) {
  return str.replace(/-([a-z])/g, function(match, letter) {
    return letter.toUpperCase();
  });
}

export function calcAverage(total, num, defaultPercent = 0) {
  if (!total) return defaultPercent;
  return 0 + Math.round(((num * 100) / total) * 10) / 10;
}

export function getClientTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';
}

export const exportSubscribersCSV = (data, filename, header) => {
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [header]);
  XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'string', cellStyles: true });
  const finalData = new Blob([excelBuffer], { type: 'text/csv' });
  const csv = URL.createObjectURL(finalData);
  const link = document.createElement('a');
  link.download = `${filename}.csv`;
  link.href = csv;
  link.click();
};

export const formatTimeMinutesHours = minutes => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (hours > 0 && remainingMinutes > 0) {
    result += ' ';
  }
  if (remainingMinutes > 0) {
    result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
  }

  return result;
};

export const fixDecimal = (number, decimalAmount) => number - Math.floor(number) ? number.toFixed(decimalAmount) : number;
