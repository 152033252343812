import Layout from '@/components/Layout';
import ProtectedDemoRoute from '@/components/ProtectedDemoRoute';
import ProtectedRoute from '@/components/ProtectedRoute';
import AdminChat from '@/components/screens/AdminChat';
import CheckVersionPage from '@/components/screens/Auth/CheckVersion';
import SignIn from '@/components/screens/Auth/SignIn';
import SignUpDemo from '@/components/screens/Auth/SignUpDemo';
import DemoChat from '@/components/screens/Chat/DemoChat';
import Dashboard from '@/components/screens/Dashboard';
import DemoDashboard from '@/components/screens/Dashboard/DemoDashboard';
import DemoReports from '@/components/screens/Dashboard/Reports/Demo';
import EmailReview from '@/components/screens/EmailReview';
import { DemoEmailReview } from '@/components/screens/EmailReview/DemoEmailReview';
import ReportStats from '@/components/screens/EmailStats';
import DemoReportStats from '@/components/screens/EmailStats/DemoReportStats';
import ShopifyIntegration from '@/components/screens/Settings/Shopify';
import EnableShopifyApp from '@/components/screens/Settings/Shopify/EnableShopifyApp';
import InstallShopifyApp from '@/components/screens/Settings/Shopify/InstallShopifyApp';

import { getVersion } from '@/services/api/auth';
import { useAuth } from '@/services/hooks/useAuth';
import { initNotifications } from '@/services/pushNotifications';
import { initDemoNotifications } from '@/services/pushNotifications/demo';
import PersistentStorage from '@/services/storage';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import DemoLayout from '@components/DemoLayout';
import Assets from '@components/screens/Assets';
import Chat from '@components/screens/Chat';
import Reports from '@components/screens/Dashboard/Reports';
import Settings from '@components/screens/Settings';
import { Transition } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { createBrowserRouter, Outlet, Route, RouterProvider, Routes, useNavigate } from 'react-router-dom';
import semver from 'semver';

// right way of logging things on Mobile device iOS Android
let log = console.log.bind(console);

const Main = () => {
  const Wrapper = () => {
    const auth = useAuth();
    // const appState = AppState.useContainer();

    const [isCurrVersion, setIsCurrVersion] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        const slug = event.url.split('.ai').pop();
        if (slug) {
          navigate(slug);
        }
        log('SLUG', event.url);
      });
    }, []);

    const checkVersionData = useQuery(
      ['version'],
      () => getVersion(),
      {
        refetchInterval: 10000,
      },
    );

    const checkVersion = async () => {
      const currVersion = checkVersionData?.data?.data?.data?.version;
      if (Capacitor.isNativePlatform()) {
        const app = await App.getInfo();
        log('APP-INSTALLED-VERSION', app.version, 'APP-CURRENT-VERSION', currVersion, semver.gte(app.version, currVersion));
        if (currVersion !== undefined && semver.gte(app.version, currVersion)) {
          setIsCurrVersion(true);
        } else {
          setIsCurrVersion(false);
        }
      }
    };

    useEffect(() => {
      checkVersion();
    }, [checkVersionData?.data?.data?.data?.version]);

    useEffect(() => {
      (async () => {
        const { platform } = await Device.getInfo();
        // appState.installed.setPlatform(platform);
      })();
    }, []);

    useEffect(() => {
      if (auth.accessToken && Capacitor.isNativePlatform()) {
        if (!auth.isDemo) {
          initNotifications(
            auth.accessToken,
            ({ data }) => {
              if (data?.type === 'message') {
                navigate('/chat', { state: { newMessages: true } });
              } else if (data?.type === 'review') {
                navigate(`/review/${data.id}`);
              } else if (data?.type === 'report') {
                navigate(`/reports/${data.id}`);
              }
            },
          );
        } else {
          initDemoNotifications(
            auth.accessToken,
            ({ data }) => {
              if (data?.type === 'message') {
                navigate('/demo/chat?notification=true', { state: { newMessages: true } });
              } else if (data?.type === 'review') {
                navigate(`/demo/review/${data.id}`);
              } else if (data?.type === 'report') {
                navigate(`/demo/reports/${data.id}`);
              }
            },
          );
        }
      }
    }, [auth.accessToken, auth.isDemo]);

    return isCurrVersion ? <Outlet /> : <CheckVersionPage />;
  };

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )',
        marginTop: 'env(safe-area-inset-top)',
        marginBottom: 'env(safe-area-inset-bottom)',
        backgroundColor: 'white',
      }}
    >
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <Wrapper />,
            children: [
              {
                path: '/login',
                element: <SignIn />,
              },
              {
                path: '/signup-demo',
                element: <SignUpDemo />,
              },
              {
                path: '/review/:draftId',
                element: (
                  <Layout>
                    <EmailReview standalonePage />
                  </Layout>
                ),
              },
              {
                path: '/admin-chat',
                element: (
                  <Layout scroll={false}>
                    <AdminChat />
                  </Layout>
                ),
              },
              {
                path: '/',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <Dashboard />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/chat',
                element: (
                  <ProtectedRoute>
                    <Layout scroll={false}>
                      <Transition mounted={true} transition='pop-bottom-right' duration={200}>
                        {styles => <Chat styles={styles} />}
                      </Transition>
                    </Layout>
                  </ProtectedRoute>
                ),
                // Route actions are the "writes" to route loader "reads".
                // Actions are called whenever the app sends a non-get submission ("post", "put", "patch", "delete") to your route.
                action: ({ params }) => {
                  return { params: 'a' };
                },
                loader: async ({ params }) => {
                  const messages = await PersistentStorage.getMessagesLocal();
                  return messages[0]?.id || null;
                },
              },
              {
                path: '/reports',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <Reports />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/reports/:draftId',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <ReportStats showHeader={false} />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/settings',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <Transition mounted={true} transition='slide-left' duration={2000}>
                        {styles => <Settings styles={styles} />}
                      </Transition>
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/settings/assets',
                element: (
                  <ProtectedRoute>
                    <Layout scroll={false}>
                      <Assets />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/settings/shopify',
                element: (
                  <ProtectedRoute>
                    <Layout scroll={true}>
                      <ShopifyIntegration />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/demo',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoDashboard />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/chat',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <Transition mounted={true} transition='pop-bottom-right' duration={200}>
                        {styles => <DemoChat styles={styles} />}
                      </Transition>
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/review/:draftId',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoEmailReview standalonePage />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/reports',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoReports />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/reports/:draftId',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoReportStats showHeader={false} />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/settings',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <Transition mounted={true} transition='slide-left' duration={2000}>
                        {styles => <Settings styles={styles} />}
                      </Transition>
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/auth/shopify',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <InstallShopifyApp />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/auth/shopify/callback',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <EnableShopifyApp />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ])}
      />
    </main>
  );
};

export default Main;
