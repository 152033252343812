import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { displaySuccessNotification } from '@/components/display/notifications';
import { useGetEmailPreview } from '@/services/api/emailPreview';
import { useGetEmailReview, useUpdateEmailReview } from '@/services/api/emailReview';
import { REVIEW_STATUS } from '@/sharedTypes';
import { useParams } from 'react-router-dom';
import EmailReviewCore, { EmailReviewProps } from './EmailReviewCore';

const EmailReview = ({ standalonePage, onClose }: EmailReviewProps) => {
  // get the params from the url slug
  const { draftId } = useParams();

  const { data: emailReview, isLoading: isLoadingEmailReview } = useGetEmailReview(draftId);
  const { data: emailPreview, isLoading: isLoadingEmailPreview } = useGetEmailPreview(draftId);
  const updateEmailReview = useUpdateEmailReview();

  if (isLoadingEmailReview || isLoadingEmailPreview) return <MailberryLoader />;

  const handleApproveEmail = (emailReviewId: string, content: Record<string, any> = {}) => {
    const localComments = JSON.parse(localStorage.getItem('comments_' + emailReviewId)!);
    const previousComments = content?.comments;

    const comments = { ...previousComments, ...localComments };

    updateEmailReview.mutateAsync({ id: emailReviewId, status: REVIEW_STATUS.APPROVED, content: { comments } }).then(d => {
      localStorage.removeItem('comments_' + emailReviewId);
      if (d.id) {
        displaySuccessNotification('Review Sent', 'The email has been successfully approved', 5000);
        onClose && onClose();
      }
    });
  };

  const handleRequestChanges = (emailReviewId: string, content: Record<string, any> = {}) => {
    const localComments = JSON.parse(localStorage.getItem('comments_' + emailReviewId)!);
    const previousComments = content?.comments;

    const comments = { ...previousComments, ...localComments };

    updateEmailReview.mutateAsync({ id: emailReviewId, status: REVIEW_STATUS.CHANGES_REQUESTED, content: { comments } }).then(d => {
      localStorage.removeItem('comments_' + emailReviewId);
      if (d.id) {
        displaySuccessNotification('Review Sent', 'The email has been sent back for changes', 5000);
        onClose && onClose();
      }
    });
  };

  return (
    <>
      <EmailReviewCore
        draftId={draftId}
        emailPreview={emailPreview}
        emailReview={emailReview}
        standalonePage={standalonePage}
        onApprove={handleApproveEmail}
        onRequestChanges={handleRequestChanges}
        isLoading={updateEmailReview.isLoading}
      />
    </>
  );
};

export default EmailReview;
