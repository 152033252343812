import ReloadTokens, { ReloadTokenType } from '@/services/state/ReloadTokensState';
import { Web } from '@/sharedTypes';
import { DemoDelivery, DemoDraft } from '@prisma/client';
import { genericAuthRequest, RuntimeBackend, useMailberryQuery } from '../base';

export function useGetDemoDraft(demoDraftId: string) {
  return useMailberryQuery<{ data: DemoDraft & { delivery: DemoDelivery; }; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/draft`, { demoDraftId }, RuntimeBackend);
    },
    queryKey: [demoDraftId],
    options: {
      cacheTime: 0,
    },
  });
}

export function useGetAllDemoDrafts(queryKey: string) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<{ data: {}; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/drafts`, {}, RuntimeBackend);
    },
    queryKey: [reloadTokens[ReloadTokenType.Draft], queryKey],
    options: { refetchInterval: 10000 },
  });
}
