import { useGetDemoAccount, useRestartDemo } from '@/services/api/demo/account';
import ReloadTokens, { ReloadTokenType } from '@/services/state/ReloadTokensState';
import { Box, Button, LoadingOverlay, Space, Stack } from '@mantine/core';
import { useTour } from '@reactour/tour';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountInfo from '../AccountInfo';
import OverallStats from '../OverallStats';
import DemoReviewList from './DemoReviewList';

export default function DemoOverview() {
  const { data, isLoading: isLoadingUser } = useGetDemoAccount();

  const navigate = useNavigate();
  const restartDemo = useRestartDemo();
  const { setReloadToken } = ReloadTokens.useContainer();

  const resetDemo = () => {
    restartDemo.mutateAsync({})
      .then(() => {
        setReloadToken(ReloadTokenType.Review);
        localStorage.removeItem('tourCompleted');
        navigate('/demo');
      });
  };

  return (
    <Stack gap={0}>
      <AccountInfo account={data?.data} isLoading={isLoadingUser} isDemo />
      <OverallStats account={data?.data} isLoading={isLoadingUser} />
      <DemoReviewList loginCount={data?.data.loginCount} />
      {process.env.NODE_ENV !== 'production' && <Button onClick={resetDemo}>reset demo</Button>}
    </Stack>
  );
}
