import MBMonitor from '@/assets/icons/MBMonitor';
import MBSmartphone from '@/assets/icons/MBSmartphone';
import { useGetAccount } from '@/services/api/account';
import { useGetEmailPreview } from '@/services/api/emailPreview';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { DEFAULT_EDITOR_STYLES } from '@lib/constants';
import { Box, Button, Center, Group, Loader, ScrollArea, Stack, Text, Transition } from '@mantine/core';
import { Divider, Space, TypographyStylesProvider } from '@mantine/core';
import AppState from '@services/state/AppState';
import parse, { Element } from 'html-react-parser';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EmailAddressAndUnsubscribe, EmailCreatedWithMailberry, EmailPreviewFooter } from '../EmailReview/EmailPreviewFooter';

export function EmailPreviewDesktop({ draft, account, showDesktopVersion }) {
  const appState = AppState.useContainer();
  const iframeRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.height = iframe.contentWindow.document.body.scrollHeight + 30 + 'px';
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleResize);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleResize);
      }
    };
  }, [draft?.editor]);

  const { editorColor, fontColor, borderWidth, borderColor, borderRadius, backgroundColor } = draft?.styles || DEFAULT_EDITOR_STYLES;
  const desktopStyles = {
    backgroundColor: editorColor,
    paddingTop: 24,
    paddingLeft: 48,
    paddingRight: 48,
    paddingBottom: 20,
    border: `${borderWidth}px solid ${borderColor}`,
    borderRadius: `${borderRadius}px`,
  };
  const mobileStyles = {
    boder: `none !important`,
    backgroundColor: editorColor,
    borderRadius: `0`,
  };

  return (
    <Box className={showDesktopVersion ? 'editor-preview-desktop' : 'editor-preview-mobile'} style={{ width: showDesktopVersion ? 600 : 375, padding: 0 }}>
      <Space h={25} />
      <Stack w={'100%'} align='left' gap={6}>
        <Group gap={0}>
          <Text size={'16'} fw={700}>
            {draft?.fromName}
          </Text>
          <Text
            span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: showDesktopVersion ? '100%' : '90%',
              fontSize: '20px',
              lineHeight: '26px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            <Text span c='gray' inherit>From:</Text>
            {` <${draft?.fromAddress}>`}
          </Text>
        </Group>
        <Text
          span
          style={{
            margin: '0 !important',
            fontSize: '20px',
            lineHeight: '24px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <Text span c='gray' inherit>Subject:</Text>
          {` ${draft.subject}`}
        </Text>
      </Stack>
      <Space h={24} />
      <Divider color={'#E7E7E7'} />
      <Space h={24} />
      <Box
        w={showDesktopVersion ? 600 : '100%'}
        style={{ fontSize: '14px', textAlign: 'left', padding: showDesktopVersion ? '3em 16px 0 16px ' : '0', backgroundColor: showDesktopVersion ? backgroundColor : '#ffffff' }}
      >
        {/* {draft?.editor === EMAIL_EDITOR_TYPE.TIPTAP && ( */}
        <Box className={showDesktopVersion ? 'editor-preview-desktop' : 'editor-preview-mobile'} style={showDesktopVersion ? desktopStyles : mobileStyles}>
          <TypographyStylesProvider>
            <div style={{ display: 'flex', flexDirection: 'column', color: fontColor }} dangerouslySetInnerHTML={{ __html: draft.body }} />

            <EmailAddressAndUnsubscribe businessMailAddress={account.businessAddress} styles={draft.styles} />
          </TypographyStylesProvider>
        </Box>
        {/* )} */}
        <EmailCreatedWithMailberry styles={draft.styles} />
        {/* {draft?.editor === EMAIL_EDITOR_TYPE.CODE && <AutoHeightIframe htmlContent={draft.body} />} */}
      </Box>
      <Space h={24} />
    </Box>
  );
}

export const EmailPreviewWrapper = ({ standalonePage, draft, isLoading }: { standalonePage?: boolean; draft?: any; isLoading?: boolean; }) => {
  // get the query params from the url
  const [searchParams] = useSearchParams();

  const isMobile = useCheckMobileScreen();
  const [showDesktopVersion, setShowDesktopVersion] = useState(isMobile ? false : true);

  const { data: account, isLoading: isLoadingUser } = useGetAccount();

  if (isLoading || isLoadingUser) {
    return (
      <Center style={{ height: '50dvh', width: '100%' }}>
        <Loader size='lg' />
      </Center>
    );
  }

  return (
    <Box
      style={{
        height: !standalonePage ? '100%' : isMobile ? '100%' : '80dvh', // height: standalonePage? isMobile ? '100%' : '80%' : isMobile ? '91dvh': '80dvh',
        display: 'flex',
        justifyContent: standalonePage ? 'space-evenly' : '',
        alignItems: standalonePage ? 'center' : '',
        gap: isMobile ? 0 : '2rem',
        position: 'relative',
      }}
      w={'100%'}
      px={isMobile ? 0 : 4}
      py={isMobile ? 0 : 4}
    >
      {isMobile && <EmailPreviewDesktop draft={draft} showDesktopVersion={showDesktopVersion} account={account} />}
      {!isMobile
        && (
          <Stack
            gap={2}
            style={{}}
            p={0}
            h={'100%'}
            align='center'
            w={'100%'}
          >
            <Group
              justify='center'
              display={isMobile ? 'none' : 'flex'}
              gap={4}
              m={'sm'}
            >
              <Button
                onClick={() => {
                  setShowDesktopVersion(true);
                }}
                variant={showDesktopVersion ? 'light' : 'white'}
              >
                <MBMonitor size={24} color={showDesktopVersion ? '#3B82F6' : '#6B7280'} />
              </Button>
              <Button
                onClick={() => {
                  setShowDesktopVersion(false);
                }}
                variant={!showDesktopVersion ? 'light' : 'white'}
              >
                <MBSmartphone size={24} color={!showDesktopVersion ? '#3B82F6' : '#6B7280'} />
              </Button>
            </Group>
            <ScrollArea style={{ height: '100%' }}>
              <Group justify='center' align='center' h={'100%'} w={'100%'}>
                <EmailPreviewDesktop draft={draft} showDesktopVersion={showDesktopVersion} account={account} />
              </Group>
            </ScrollArea>
          </Stack>
        )}
    </Box>
  );
};
