import AppState from '@/services/state/AppState';
import { Button, Group, Stack, Textarea } from '@mantine/core';
import { Cross1Icon, PaperPlaneIcon } from '@radix-ui/react-icons';
import { useTour } from '@reactour/tour';
import { useEffect, useState } from 'react';

interface CommentInputProps {
  onClose: () => void;
  isVisible: boolean;
  handleAddComment: (comment: string) => void;
  isMobile?: boolean;
  styles?: React.CSSProperties;
}

const tourDefaultMessage = "Let's bring some excitement to the opening line.";

const CommentInput: React.FC<CommentInputProps> = ({ onClose, isVisible, handleAddComment, isMobile, styles }) => {
  const [commentValue, setCommentValue] = useState('');
  const { setCurrentStep, setIsOpen, isOpen, currentStep } = useTour();
  const appState = AppState.useContainer();

  useEffect(() => {
    if (isVisible && appState.userAndCustomer.isInTour && currentStep === 3) {
      setCommentValue(tourDefaultMessage);
      setIsOpen(true);
    }
  }, [currentStep, isVisible]);

  const onSaveComment = () => {
    if (commentValue && commentValue.trim() !== '') {
      handleAddComment(commentValue);
      setCommentValue('');
    }
    onClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (appState.signupAndLogin.isDemo && isOpen) return;
    setCommentValue(event.target.value);
  };

  if (!isVisible) return null;

  return (
    <>
      {isMobile
        ? (
          <Group
            className='tour-review-input'
            style={{
              position: isMobile ? 'absolute' : 'relative',
              bottom: 0,
              backgroundColor: 'white',
              width: '100%',
              zIndex: 100,
              ...styles,
            }}
            gap='0.4em'
            align='center'
            justify='space-between'
            wrap='nowrap'
            p={isMobile ? '0.5em' : '2em'}
            w={isMobile ? '100%' : '50%'}
            bg={isMobile ? 'white' : 'transparent'}
          >
            <Button
              disabled={isOpen}
              size={isMobile ? 'sm' : 'lg'}
              radius='50%'
              style={{ width: 'auto', aspectRatio: '1/1', padding: 0 }}
              color={'gray'}
              leftSection={''}
              onClick={onClose}
            >
              <Cross1Icon
                style={{ width: 'auto', height: '70%', color: 'white', margin: 'auto', padding: '2px' }}
              />
            </Button>

            <Textarea
              value={commentValue}
              autoFocus
              autoComplete='off'
              w='80%'
              p={0}
              placeholder='Add a comment...'
              radius='20px'
              size={isMobile ? 'sm' : 'lg'}
              autosize
              minRows={1}
              maxRows={4}
              onChange={e => handleInputChange(e)}
            />
            {/* <Group justify='center' gap={10} mt={2} grow p={0}> */}
            <Button
              size={isMobile ? 'sm' : 'lg'}
              radius='50%'
              style={{ width: 'auto', aspectRatio: '1/1', padding: 0 }}
              leftSection={''}
              onClick={onSaveComment}
            >
              <PaperPlaneIcon
                style={{ width: 'auto', height: '70%', color: 'white', margin: 'auto', padding: '2px' }}
              />
            </Button>
          </Group>
        )
        : (
          <Stack className='tour-review-input' style={{ position: 'relative', bottom: 0, backgroundColor: 'white', width: '100%', ...styles }} gap={0} p={10}>
            <Textarea
              value={commentValue}
              autoFocus
              autoComplete='off'
              w='100%'
              placeholder='Add a comment...'
              size={'lg'}
              autosize
              minRows={1}
              maxRows={4}
              onChange={e => handleInputChange(e)}
              m={10}
              mx={0}
            />
            <Group justify='center' gap={10} mt={2} grow p={0}>
              <Button disabled={isOpen} onClick={onClose} style={{ height: '3rem' }} color={'blue'} variant='outline'>
                Cancel
              </Button>
              <Button onClick={onSaveComment} style={{ height: '3rem' }} color={'blue'} variant='outline'>
                Save comment
              </Button>
            </Group>
          </Stack>
        )}
    </>
  );
};

export default CommentInput;
