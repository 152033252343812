import { useGetAccount } from '@/services/api/account';
import { useRestartDemo } from '@/services/api/demo/account';
import { Box, Button, ScrollArea, Stack } from '@mantine/core';
import { useTour } from '@reactour/tour';
import React from 'react';
import AccountInfo from './AccountInfo';
import OverallStats from './OverallStats';
import ReviewList from './ReviewList';

export default function Overview() {
  const { data: account, isLoading: isLoadingUser } = useGetAccount();

  const { setCurrentStep, setIsOpen, currentStep, isOpen, inViewThreshold } = useTour();

  const restartDemo = useRestartDemo();
  const resetDemo = () => {
    restartDemo.mutateAsync({})
      .then(() => {
        localStorage.removeItem('tourCompleted');
        setCurrentStep(0);
        setIsOpen(true);
      });
  };

  return (
    <>
      <AccountInfo account={account} isLoading={isLoadingUser} />
      <OverallStats account={account} isLoading={isLoadingUser} />
      <ReviewList />
      {/* <Button onClick={resetDemo}>reset demo</Button> */}
    </>
  );
}
