const MailberryLogo = () => {
  return (
    <svg width='162' height='36' viewBox='0 0 142 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M135.432 11.1856H135.947L135.953 7.95623L137.249 7.96154L137.243 7.46757H134.131L134.136 7.96154H135.437L135.432 11.1856ZM137.688 11.1856H138.219V8.40771L139.287 11.1856H139.77L140.822 8.41833V11.1856H141.332V7.46757H140.689L139.536 10.4792L138.352 7.46757H137.688V11.1856Z'
        fill='#2B3554'
      >
      </path>
      <path
        d='M40.7392 17.1695C40.7392 14.1756 38.6008 11.8767 35.4732 11.8767C33.6021 11.8767 32.1586 12.7588 31.3299 14.1488C30.528 12.7588 29.0845 11.8767 27.2133 11.8767C24.1125 11.8767 21.9473 14.1756 21.9473 17.1695V25.9907H24.2195V17.0893C24.2195 15.2983 25.3956 13.9617 27.2133 13.9617C29.031 13.9617 30.2072 15.2983 30.2072 17.0893V25.9907H32.4793V17.0893C32.4793 15.2983 33.6555 13.9617 35.4732 13.9617C37.2909 13.9617 38.4671 15.2983 38.4671 17.0893V25.9907H40.7392V17.1695Z'
        fill='#2B3554'
      >
      </path>
      <path
        d='M57.2834 19.1208C57.2834 15.0577 54.2896 11.8767 50.3868 11.8767C46.5643 11.8767 43.4635 14.9775 43.4635 19.1208C43.4635 23.2374 46.5376 26.3382 50.3334 26.3382C52.2847 26.3382 54.0223 25.3491 55.0915 23.8522V25.9907H57.2834V19.1208ZM55.038 19.1208C55.038 21.9543 52.9263 24.2264 50.3868 24.2264C47.8207 24.2264 45.7356 22.0078 45.7356 19.1208C45.7356 16.2339 47.8207 13.9617 50.3868 13.9617C52.8996 13.9617 55.038 16.2606 55.038 19.1208Z'
        fill='#2B3554'
      >
      </path>
      <path d='M63.0413 7.43937H60.7691V10.1392H63.0413V7.43937ZM63.0413 12.2242H60.7691V25.9907H63.0413V12.2242Z' fill='#2B3554'></path>
      <path d='M69.307 6.10281H67.0348V25.9907H69.307V6.10281Z' fill='#2B3554'></path>
      <path
        d='M87.0925 19.1476C87.0925 15.1379 84.1788 11.8767 80.2226 11.8767C78.2445 11.8767 76.5337 12.9727 75.5447 14.3092V6.10281H73.2726V25.9907H75.4645V23.772C76.4268 25.1888 78.1911 26.3382 80.2226 26.3382C84.1788 26.3382 87.0925 23.1037 87.0925 19.1476ZM84.8204 19.1476C84.8204 21.9008 82.7888 24.173 80.2226 24.173C77.6832 24.173 75.5447 21.9008 75.5447 19.1476C75.5447 16.3408 77.6832 14.0419 80.2226 14.0419C82.7888 14.0419 84.8204 16.3408 84.8204 19.1476Z'
        fill='#2B3554'
      >
      </path>
      <path
        d='M100.137 21.7137C99.3881 23.3176 97.9981 24.2532 96.0468 24.2532C93.7746 24.2532 91.5827 22.5691 91.2886 19.7089H102.863C102.89 14.8171 99.4683 11.8767 95.9131 11.8767C92.0906 11.8767 88.9898 15.1379 88.9898 19.1208C88.9898 23.1572 92.0906 26.3115 96.0468 26.3115C99.2277 26.3115 101.58 24.6541 102.542 21.7137H100.137ZM91.4223 17.6773C92.0104 15.325 94.0152 13.935 95.9131 13.935C97.9179 13.935 99.7891 15.4319 100.35 17.6773H91.4223Z'
        fill='#2B3554'
      >
      </path>
      <path d='M105.455 17.811V25.9907H107.7V17.7575C107.7 15.1112 109.384 13.8281 112.031 14.0687V11.9569C108.422 11.5292 105.455 13.641 105.455 17.811Z' fill='#2B3554'></path>
      <path d='M114.383 17.811V25.9907H116.628V17.7575C116.628 15.1112 118.312 13.8281 120.959 14.0687V11.9569C117.35 11.5292 114.383 13.641 114.383 17.811Z' fill='#2B3554'></path>
      <path
        d='M134.644 12.2242H132.212L128.282 23.1839L124.273 12.2242H121.814L127.08 25.9105L126.358 27.8351C125.77 29.439 125.021 30.0003 123.23 29.8667V31.9517C125.984 32.2458 127.507 31.2032 128.47 28.6371L134.644 12.2242Z'
        fill='#2B3554'
      >
      </path>
      <path
        d='M6.62543 25.9439C10.2846 25.9439 13.2509 22.9776 13.2509 19.3185C13.2509 15.6593 10.2846 12.693 6.62543 12.693C2.96631 12.693 0 15.6593 0 19.3185C0 22.9776 2.96631 25.9439 6.62543 25.9439Z'
        fill='#2B3554'
      >
      </path>
      <path d='M0.279053 12.4138C0.279053 12.4138 0.418536 0.418265 12.8325 -0.000183105C12.8325 -0.000183105 12.5535 11.4374 0.279053 12.4138Z' fill='#90C884'></path>
    </svg>
  );
};

export default MailberryLogo;
