import { Anchor, Badge, Box, Button, Container, Divider, Group, LoadingOverlay, NumberInput, Space, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useDisableIntegration, useGetIntegrations, useUpdateAttributionWindow } from '@/services/api/shopify';
import { useEffect, useRef } from 'react';
import Metrics from './Metrics';

const ShopifyIntegration = () => {
  const { isLoading, data: integration } = useGetIntegrations();
  const disableIntegration = useDisableIntegration();
  const updateAttributionWindow = useUpdateAttributionWindow();

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current?.value) {
      updateAttributionWindow.mutateAsync({ days: Math.trunc(Number(ref.current.value)) }).catch(e => console.error(e));
    }
  }, [ref?.current?.value]);

  const handleClick = async () => {
    return disableIntegration.mutateAsync({}).catch(e => console.error(e));
  };

  const form = useForm({
    initialValues: {
      attributionWindow: integration?.attributionWindow || 5,
    },
  });

  return (
    <Stack h={'100%'} justify='flex-start' gap={24} px={8}>
      <LoadingOverlay visible={isLoading} />
      <>
        <Title mt={12} mx={'auto'}>Shopify</Title>
        <Group justify='space-between'>
          <Text fw={700}>
            Status
          </Text>
          <Badge
            size='lg'
            color={integration?.active ? 'green' : 'red'}
            variant='filled'
          >
            {integration?.active ? 'Active' : 'Inactive'}
          </Badge>
        </Group>
        {integration?.active
          && (
            <>
              <Group justify='space-between'>
                <Text fw={700}>
                  Shop URL
                </Text>
                <Anchor target='_blank' href={'https://' + integration?.url}>{integration?.url}</Anchor>
              </Group>

              <Group wrap='nowrap' justify='space-between'>
                <Text flex={1} fw={700}>
                  Atribution window
                </Text>
                <NumberInput
                  ref={ref}
                  min={1}
                  size={'md'}
                  {...form.getInputProps('attributionWindow')}
                  style={t => ({
                    width: '75px',
                    label: {
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      color: 'blue',
                      marginBottom: '8px',
                    },
                  })}
                />
                <Text>
                  days
                </Text>
              </Group>
            </>
          )}

        {integration?.active && <Button mx={'auto'} loading={disableIntegration.isLoading} w={200} size={'md'} onClick={handleClick}>Remove</Button>}
      </>

      {integration?.active && (
        <>
          <Divider />
          <Metrics />
        </>
      )}
    </Stack>
  );
};

export default ShopifyIntegration;
