import { REPORT_VIEW_TABS } from '@/lib/types';
import DeliveryReportStats from '@/mailberry/Stats/DeliveryReportStats';
import { useGetDemoDraft } from '@/services/api/demo/draft';
import AppState from '@/services/state/AppState';
import { Center, Group, Loader } from '@mantine/core';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Overview from './Overview';

const stylesChildren = [
  { border: true },
];

const DemoReportStats = ({ showHeader }) => {
  const { draftId } = useParams();
  const { data, isLoading } = useGetDemoDraft(draftId);
  const { setIsOpen, currentStep, setCurrentStep, isOpen } = useTour();
  const appState = AppState.useContainer();

  useEffect(() => {
    if (appState.userAndCustomer.isInTour && data?.data?.delivery && currentStep === 11) {
      setCurrentStep(currentStep + 1);
      setIsOpen(true);
    }
  }, [currentStep, data?.data?.delivery]);

  if (isLoading) {
    return (
      <Group justify='center' align='center' w={'100%'} h={'100%'}>
        <Loader size={24} color='gray' />
      </Group>
    );
  }

  return (
    (data?.data && data?.data?.delivery)
      ? (
        <DeliveryReportStats tabs={[REPORT_VIEW_TABS.OVERVIEW]} showHeader={showHeader} stylesChildren={stylesChildren}>
          <Overview draft={data?.data} delivery={data?.data?.delivery} links={[]} />;
        </DeliveryReportStats>
      )
      : (
        <Group justify='center' align='center' w={'100%'} h={'100%'}>
          <h1>Report Not Found</h1>
        </Group>
      )
  );
};

export default DemoReportStats;
