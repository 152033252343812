import { formatDate, formatTimeMinutesHours } from '@/lib/utils';
import { MBStatsItem } from '@/mailberry/MBStatsItem';
import AppState from '@/services/state/AppState';
import { MESSAGE_TYPE } from '@/sharedTypes';
import MBClicked from '@assets/icons/MBClicked';
import MBOpen from '@assets/icons/MBOpen';
import { fixDecimal } from '@lib/utils';
import { Group, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export interface MetadataStats {
  draftId: string;
  name: string;
  openRate: number;
  clickRate: number;
  minutesAfterSent: number;
}

interface MessageStatsProps {
  message: string;
  time: string;
  isSender: boolean;
  metadata?: MetadataStats | Record<any, any>;
  type: MESSAGE_TYPE;
}

const MessageStats = ({ time, isSender, metadata }: MessageStatsProps) => {
  const date = formatDate(time);
  const navigate = useNavigate();
  const appState = AppState.useContainer();

  const handleOpenStats = () => {
    if (appState.signupAndLogin.isDemo) {
      // return navigate(`/demo/reports/${metadata?.draftId}`);
    }
    return navigate(`/reports/${metadata?.draftId}`);
  };

  return (
    <Group justify={isSender ? 'flex-end' : 'flex-start'}>
      <Stack
        gap={0}
        p={6}
        style={{
          width: '90%',
          backgroundColor: '#0060ff',
          // borderLeft: isSender ? '4px solid #b7b7b7' : 'none',
          // borderRight: isSender ? 'none' : '4px solid #b7b7b7',
          borderTopRightRadius: '1em',
          borderTopLeftRadius: '1em',
          borderBottomRightRadius: isSender ? 0 : '1em',
          borderBottomLeftRadius: isSender ? '1em' : 0,
          overflowWrap: 'break-word',
          whiteSpace: 'break-spaces',
          position: 'relative',
          cursor: 'pointer',
          transition: 'transform 200ms ease-in-out',
          ':hover': {
            transform: 'scale(1.01)',
          },
        }}
        onClick={handleOpenStats}
      >
        <Stack style={{ width: '80%', paddingLeft: 8 }} gap={0}>
          <Text size='lg' fw={700} lineClamp={1} c={'white'}>
            📊{`  `}Email Stats ({formatTimeMinutesHours(metadata.minutesAfterSent)})
          </Text>
          <Stack gap={0} align='center'>
            <Text size='md' c='white'>
              {metadata?.name}
            </Text>
            <Group gap={'lg'} justify='center' w={'100%'} m={'xs'}>
              <MBStatsItem icon={<MBOpen size={24} stroke='white' />} title={'Open Rate'} value={`${fixDecimal(metadata.openRate, 1)}%`} color={'white'} />
              <MBStatsItem icon={<MBClicked size={24} stroke='white' />} title={'Click Rate'} value={`${fixDecimal(metadata.clickRate, 1)}%`} color={'white'} />
            </Group>
          </Stack>
        </Stack>
        <Text size='xs' c='white' style={{ whiteSpace: 'nowrap', position: 'absolute', right: 0, bottom: 2, marginRight: 8 }}>
          {date}
        </Text>
      </Stack>
    </Group>
  );
};

export default MessageStats;
