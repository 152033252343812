import { genericAuthRequest, RuntimeBackend, useMailberryQuery } from '../base';

export function useGetDemoDeliveries() {
  return useMailberryQuery<{ data: []; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/deliveries`, {}, RuntimeBackend);
    },
    options: { refetchInterval: 30000 },
  });
}
