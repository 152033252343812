import { CONVERSION_METRICS, Web } from '@/sharedTypes';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, useMailberryMutate, useMailberryQuery } from './base';

export function useGetIntegrations() {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<Web.Response.ShopifyIntegration>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/customer/shopifyIntegration`);
    },
    queryKey: [reloadTokens[ReloadTokenType.UI]],
    options: { refetchInterval: 5000 },
  });
}

export function useEnableIntegration() {
  return useMailberryMutate<{}, { code: string; hmac: string; state: string; shop: string; timestamp: string; host: string; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/attribution/shopify/enable`, userData);
    },

    reloadTokenType: ReloadTokenType.UI,
  });
}

export function useInstallIntegration() {
  return useMailberryMutate<string | null, { code: string; hmac: string; session: string; shop: string; timestamp: string; host: string; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/attribution/shopify/install`, userData);
    },

    reloadTokenType: ReloadTokenType.UI,
  });
}

export function useDisableIntegration() {
  return useMailberryMutate<{}, any>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/attribution/shopify/disable`, userData);
    },

    reloadTokenType: ReloadTokenType.UI,
  });
}

export function useUpdateAttributionWindow() {
  return useMailberryMutate<{}, { days: number; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'put', `/attribution/shopify/window`, userData);
    },

    reloadTokenType: ReloadTokenType.UI,
  });
}

export function useGetConversionMetric(userData: { metric: CONVERSION_METRICS; timePeriod: [Date, Date]; }) {
  return useMailberryQuery<Web.Response.GetMetric>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/attribution/shopify/metric`, userData);
    },
    queryKey: [userData.metric, userData.timePeriod.join('_')],
    options: { refetchInterval: 5000, enabled: Boolean(userData.timePeriod[1]) },
  });
}
