import { Web } from '@/sharedTypes';
import { DELIVERY_SUBSCRIBER_FILTERS } from '@lib/types';
import { UseQueryOptions } from '@tanstack/react-query';
import { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, useMailberryMutate, useMailberryQuery } from './base';

// export function useSendTestEmail(onSuccess?: () => void, onError?: () => void) {
//   return useMailberryMutate<Web.Response.ISendPreview, Web.Request.SendTestEmail>({
//     f: (accessToken, userData) => {
//       return genericAuthRequest(accessToken, 'post', `/delivery/sendPreview`, userData);
//     },
//     options: { onSuccess, onError },
//     reloadTokenType: ReloadTokenType.Draft,
//   });
// }

// export function useScheduleDelivery(onSuccess = () => {}, onError = () => {}, customErrorHandlers?) {
//   return useMailberryMutate<Web.Response.Schelude, Web.Request.ScheduleDelivery>({
//     f: (accessToken, userData) => {
//       return genericAuthRequest(accessToken, 'post', `/delivery/scheduleDelivery`, userData);
//     },
//     options: { onSuccess, onError },
//     reloadTokenType: ReloadTokenType.Draft,
//     customErrorHandlers,
//   });
// }

// export function useUnscheduleDelivery(onSuccess?: () => void, onError?: () => void) {
//   return useMailberryMutate<Web.Response.DraftDelivery, Web.Request.UnscheduleDelivery>({
//     f: (accessToken, userData) => {
//       const deliveryId = userData.id;
//       delete userData.id;
//       return genericAuthRequest(accessToken, 'delete', `/delivery/unschedule/${deliveryId}`);
//     },
//     options: { onSuccess, onError },
//     reloadTokenType: ReloadTokenType.Draft,
//   });
// }

// TODO: make useDeliverySendNow types accepting preHeader
// make preHeader load with draft load.

// export function useDeliverySendNow(customErrorHandlers, options?: UseQueryOptions) {
//   return useMailberryMutate<Web.Response.Schelude, Web.Request.DeliverySendNow>({
//     f: (accessToken, userData) => {
//       return genericAuthRequest(accessToken, 'post', `/delivery/sendNow`, userData);
//     },
//     options: options,
//     reloadTokenType: ReloadTokenType.Draft,
//     customErrorHandlers,
//   });
// }

export function useGetDeliveryLinks(id: string) {
  return useMailberryQuery<Web.Response.ClickedLink[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/${id}/links`);
    },
    queryKey: [id],
    options: { refetchInterval: 10000 },
  });
}

export function useGetDeliverySubscribersWhoClickedALink(deliveryLinkId: string, limit: number, offset: number) {
  return useMailberryQuery<Web.Response.SubscriberWhoClickedALink[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/links/${deliveryLinkId}/clicks`, { limit, offset });
    },
    queryKey: ['link', deliveryLinkId, limit, offset],
    options: { refetchInterval: 30000 },
  });
}

export function useGetDeliverySubscribersWhoClickedALinkCount(deliveryLinkId: string) {
  return useMailberryQuery<Web.Response.CountTotal>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/links/${deliveryLinkId}/clicks/count`);
    },
    queryKey: ['count', deliveryLinkId],
    options: { refetchInterval: 30000 },
  });
}

export function useGetDeliverySubscribers(id: string, limit: number, offset: number, filterBy?: DELIVERY_SUBSCRIBER_FILTERS) {
  return useMailberryQuery<Web.Response.DeliverySubscriber[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/${id}/subscribers`, { filterBy, limit, offset });
    },
    queryKey: [id, filterBy, offset],
    options: { refetchInterval: 10000 },
  });
}

export function useExportDeliverySubscribers(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<any, Web.Request.ExportDeliverySubscribers>({
    f: (accessToken, userData) => {
      const { filterBy, limit, offset, selectedIds } = userData;
      return genericAuthRequest(accessToken, 'post', `/delivery/${userData.id}/export/subscribers?filterBy=${filterBy}`, {
        limit,
        offset,
        selectedIds,
      });
    },
    options: { onSuccess, onError },
    reloadTokenType: ReloadTokenType.Draft,
  });
}

export function useGetDeliverySubscribersCount(id: string, filterBy?: DELIVERY_SUBSCRIBER_FILTERS) {
  return useMailberryQuery<Web.Response.CountTotal>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery/${id}/subscribers/count`, { filterBy });
    },
    queryKey: [id, filterBy],
    options: { refetchInterval: 30000 },
  });
}

type GetScheduleDeliveries = {
  segmentName: string;
};

export function useGetScheduleDeliveries(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<Web.Response.DraftDelivery[], GetScheduleDeliveries>({
    f: (accessToken, userData) => {
      const { segmentName } = userData;
      return genericAuthRequest(accessToken, 'get', `/delivery/schedule${segmentName ? '?segmentName=' + segmentName : ''}`);
    },
    options: { onSuccess, onError },
    reloadTokenType: ReloadTokenType.UI,
  });
}

export function useGetDeliveries() {
  return useMailberryQuery<Web.Response.GetAllDeliveries[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/delivery`);
    },
    options: { refetchInterval: 30000 },
  });
}
