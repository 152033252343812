import BerryIcon from '@/assets/icons/BerryIcon';
import { formatDate } from '@/lib/utils';
import { useGetAllLastMessagesAdminInfiniteQuery } from '@/services/api/admin';
import useInView from '@/services/hooks/useInView';
import AppState from '@/services/state/AppState';
import { MESSAGE_AUTHOR } from '@/types/models';
import { Box, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { Loader2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

const Inbox = ({ onClickChat, authId }: { onClickChat: (chatId: Record<string, any>) => void; authId: string | null; }) => {
  const { data: _allLastAdminMessages, isFetchingNextPage, fetchNextPage, isLoading } = useGetAllLastMessagesAdminInfiniteQuery(20, {
    getNextPageParam: (lastPage: any) => {
      if (lastPage && !lastPage.page) return;
      // no more pages
      if (Number(lastPage.page) === lastPage.totalPages) return;
      return Number(lastPage.page) + 1;
    },
    enabled: true,
  });

  // Request more messages by scrolling up
  const inboxTopRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(inboxTopRef);

  useEffect(() => {
    if (isInView) {
      console.log('VISTO');
      fetchNextPage();
    }
  }, [isInView]);

  const handleChatClick = (currentChat: Record<string, any>) => {
    // do something else
    onClickChat(currentChat);
  };

  const [allLastAdminMessages, setAllLastAdminMessages] = useState<any>([]);

  useEffect(() => {
    const newMessages = _allLastAdminMessages?.pages.flatMap(e => e.messages) || [];
    setAllLastAdminMessages(newMessages);
  }, [_allLastAdminMessages]);

  return (
    <ScrollArea style={{ height: '100%' }}>
      <Box>
        {allLastAdminMessages && allLastAdminMessages.map(function(chat, index) {
          const isManaged = chat.createdBy === MESSAGE_AUTHOR.MANAGED_USER;
          const isPersonnel = chat.createdBy === MESSAGE_AUTHOR.MB_PERSONNEL;
          const isUnread = chat.createdBy && isManaged && chat.readAt === null;
          const formatedDate = chat.sentAt ? formatDate(chat.sentAt) : '';
          const displayText = chat.text ? chat.text : chat.type ? 'Special ' + chat.type + ' message' : '';
          const text = isPersonnel ? 'You: ' + displayText : displayText;
          return (
            <Group
              key={index}
              onClick={() => handleChatClick(chat)}
              justify='space-between'
              px={'1rem'}
              py={'0.75rem'}
              style={{
                borderBottom: '2px solid #E0E7FF',
                backgroundColor: authId === chat.authId ? '#F3F4F6' : 'transparent',
                cursor: 'pointer',
              }}
              gap={0}
            >
              <Stack style={{ display: 'flex', flexDirection: 'column', width: '65%' }} gap={0}>
                <Text fw={600}>{chat.name}</Text>
                <Text size='sm' lineClamp={2} style={{ paddingLeft: '0.25rem', overflow: 'hidden' }}>
                  {text || 'start a conversation'}
                </Text>
              </Stack>
              <Text size='xs' style={{ width: '20%' }}>{formatedDate}</Text>
              {isUnread && <Box style={{ width: '0.5rem', height: '0.5rem', backgroundColor: 'blue', borderRadius: '50%' }} />}
            </Group>
          );
        })}
      </Box>
    </ScrollArea>
  );
};

export default Inbox;
