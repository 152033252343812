// src/components/Reports.jsx
import { useGetDemoDeliveries } from '@/services/api/demo/delivery';
import { useGetAllDemoDrafts } from '@/services/api/demo/draft';
import AppState from '@/services/state/AppState';
import { ScrollArea, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import DemoReportList from './DemoReportList';

export default function DemoReports() {
  const appState = AppState.useContainer();
  const { isLoading, data: drafts } = useGetAllDemoDrafts(appState?.UI?.screen);
  const { data: deliveries, isLoading: isLoadingDeliveries } = useGetDemoDeliveries();
  const navigate = useNavigate();

  const handleViewDetails = ({ draftId }) => {
    if (appState.signupAndLogin.isDemo) {
      return navigate(`/demo/reports/${draftId}`);
    }
    return navigate(`/reports/${draftId}`);
  };

  return (
    <ScrollArea h={'100%'} w={'100%'}>
      <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
        <DemoReportList
          isLoading={isLoading}
          isLoadingDeliveries={isLoadingDeliveries}
          drafts={drafts?.data}
          deliveries={deliveries?.data}
          onView={handleViewDetails}
        />
      </Stack>
    </ScrollArea>
  );
}
