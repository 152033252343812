import { EMAILS_VIEW_TABS } from '@/lib/types';
import { useGetAccount } from '@/services/api/account';
import { useGetDeliveries } from '@/services/api/delivery';
import { useGetAllDrafts, useGetAllDraftsCount } from '@/services/api/drafts';
import { usePagination } from '@/services/hooks/usePagination';
import AppState from '@/services/state/AppState';
import { Group, Pagination, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ReportList from './ReportList';

export default function Reports() {
  const appState = AppState.useContainer();
  const { data: draftTotal, isLoading: isLoadingCount } = useGetAllDraftsCount({ status: EMAILS_VIEW_TABS.SENT }, [appState?.UI?.screen]);
  const { setPage, limit, offset, page, totalPages } = usePagination(20, draftTotal?.total);
  const { isLoading, data: drafts } = useGetAllDrafts({
    status: EMAILS_VIEW_TABS.SENT,
    limit,
    offset,
  }, [offset, limit, appState?.UI?.screen]);
  const { data: deliveries, isLoading: isLoadingDeliveries } = useGetDeliveries();
  const navigate = useNavigate();

  const handleViewDetails = ({ draftId }) => {
    navigate(`/reports/${draftId}`);
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      <ReportList
        isLoading={isLoading || isLoadingCount}
        isLoadingDeliveries={isLoadingDeliveries}
        drafts={drafts}
        deliveries={deliveries}
        onView={handleViewDetails}
      />
      <Group justify='center'>
        <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
      </Group>
    </Stack>
  );
}
