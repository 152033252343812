import { AccountType } from '@prisma/client';
import { MailBerryBackend, RuntimeBackend, useMailberryMutate } from './base';

type SignData = {
  email: string;
  code: string;
};

type AuthOtp = {
  email: string;
  accountType: AccountType;
};

type AuthOtpDemo = {
  email: string;
};

export type AuthSignupDemo = {
  email: string;
  name: string;
  bussinesName: string;
  website: string;
  code: string;
  industry: string;
};

// export function useRegisterMutation() {
//   return useMailberryMutate<{}, SignData>({
//     f: (_, userData) => {
//       return MailBerryBackend.post(`/auth/signup`, userData);
//     },
//   });
// }

export function postAuthOtp(userData: AuthOtp) {
  return RuntimeBackend.post(`/auth/otp`, userData);
}

export function postVerify(userData: SignData) {
  return RuntimeBackend.post(`/auth/otp/verify`, userData);
}

export function postAuthOtpDemo(userData: AuthOtpDemo) {
  return RuntimeBackend.post(`/auth/otp/demo`, userData);
}

export function postAuthSignupDemo(userData: AuthSignupDemo) {
  return RuntimeBackend.post(`/auth/signup/demo`, userData);
}

// export function postSignIn(userData: SignData) {
//   return MailBerryBackend.post(`/auth/signin`, userData);
// }

export function getVersion() {
  return MailBerryBackend.get(`/auth/check-version`);
}
