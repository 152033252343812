import { useGetConversionMetric } from '@/services/api/shopify';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { CONVERSION_METRICS } from '@/sharedTypes';
import { Box, Group, LoadingOverlay, Select, Stack, Text, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';

import { useState } from 'react';

const Metrics = () => {
  const isMobile = useCheckMobileScreen();
  const today = new Date();
  const defaultPeriodStart = new Date(today.setMonth(today.getMonth() - 1));
  const defaultPeriodEnd = new Date();

  const [timePeriod, setTimePeriod] = useState<[Date, Date]>([defaultPeriodStart, defaultPeriodEnd]);
  const [metric, setMetric] = useState<CONVERSION_METRICS>(CONVERSION_METRICS.CHECKOUT_COMPLETED);

  const { isLoading, data } = useGetConversionMetric({ metric, timePeriod });

  return (
    <Stack>
      <LoadingOverlay visible={isLoading && Boolean(timePeriod[1])} />
      <Title mx={'auto'}>Business</Title>
      <Group justify='center'>
        <Select
          miw={350}
          label='Conversion Metric'
          placeholder='Pick one'
          value={metric}
          onChange={e => setMetric(e as CONVERSION_METRICS)}
          defaultValue={CONVERSION_METRICS.CHECKOUT_COMPLETED}
          data={[
            { value: CONVERSION_METRICS.CHECKOUT_COMPLETED, label: 'Placed order' },
          ]}
        />

        <DatePickerInput
          miw={350}
          valueFormat='MMM DD YYYY'
          label='Time period'
          placeholder='Pick date'
          type='range'
          value={timePeriod}
          dropdownType={isMobile ? 'modal' : 'popover'}
          onChange={setTimePeriod}
        />
      </Group>

      <Group
        style={theme => ({
          marginTop: 12,
          padding: 24,
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 4,
          borderColor: 'gray',
          justifyContent: 'space-evenly',
        })}
      >
        <Stack align='center'>
          <Title>${data?.totalRevenue}</Title>
          <Text>Total revenue</Text>
        </Stack>

        <Stack align='center'>
          <Title>${data?.attributedRevenue}</Title>
          <Text>Attributed revenue</Text>
        </Stack>
      </Group>
    </Stack>
  );
};

export default Metrics;
