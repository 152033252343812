import { genericAuthRequest, RuntimeBackend } from '@/services/api/base';

import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
let log = console.log.bind(console);

export const initDemoNotifications = async (accessToken: string, actionPerformedListenerFn: (PushNotificationSchema) => void) => {
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', async (token: Token) => {
    // save token in db
    try {
      await genericAuthRequest(accessToken, 'POST', '/demo/notifications', { notificationToken: token.value }, RuntimeBackend);
    } catch (error) {
      log('Update Subscription failed', error);
    }
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error: any) => {
    log('registrationError', error);
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
    log('pushNotificationReceived');
    localStorage.setItem('haveDemoMessage', '1');
  });

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
    log('lucas', action);
    actionPerformedListenerFn(action.notification);
  });
};

// Deletes firebase token on Android and unregister APNS on iOS
export const stopDemoNotifications = async (accessToken: string) => {
  PushNotifications.unregister();
  try {
    await genericAuthRequest(accessToken, 'DELETE', '/demo/notifications', {}, RuntimeBackend);
  } catch (error) {
    log('Update Subscription failed', error);
  }
};
