import { Box, Text } from '@mantine/core';
import { ArrowDownIcon } from 'lucide-react';

export const NewMessagesIndicator = ({ newMessages, newMessagesInView, handleScrollToNewMessages }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        bottom: '4em',
        right: '1rem',
        zIndex: 20,
        transition: 'all 300ms',
        opacity: newMessagesInView ? 0 : 1,
        aspectRatio: '1/1',
        minHeight: '1em',
      }}
    >
      <Text
        style={{
          position: 'relative',
          right: '4px',
          zIndex: 10,
          color: newMessages?.data?.length > 0 ? '#EF4444' : 'transparent',
          fontWeight: 600,
          fontSize: '16px',
        }}
      >
        {newMessages?.data?.length || ''}
      </Text>
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '9999px',
          padding: '8px',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          opacity: 0.8,
          cursor: 'pointer',
          border: '1px solid #E2E8F0',
          ':hover': {
            opacity: 1,
          },
        }}
        onClick={handleScrollToNewMessages}
        h={'100%'}
        w={'100%'}
      >
        <ArrowDownIcon style={{ width: '24px', height: '24px', color: '#475569' }} />
      </Box>
    </Box>
  );
};
