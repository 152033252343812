import { Web } from '@/sharedTypes';
import { genericAuthRequest, RuntimeBackend, useMailberryMutate, useMailberryQuery } from '../base';

export function useGetDemoAccount() {
  return useMailberryQuery<{
    data: {
      firstName: string;
      email: string;
      customerDemoId: string;
      brandName: string;
      businessName: string;
      activeSubscribers: number;
      averageOpenRate: number;
      campaingsSent: number;
      loginCount: number;
    };
  }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/account`, {}, RuntimeBackend);
    },
    options: { refetchInterval: 10000, cacheTime: 10000 },
  });
}

export function useRestartDemo() {
  return useMailberryMutate<{ data: {}; message: string; }, {}>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'post', `/demo/restart`, {}, RuntimeBackend);
    },
    // reloadTokenType: ReloadTokenType.Comments,
  });
}
