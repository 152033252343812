import { Stack } from '@mantine/core';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './TabsMenuButton.module.scss';

export default function TabMenuButton(
  { children, route, location, disabled = false, paramState = {} }: { children: ReactNode; route: string; location: string; disabled?: boolean; paramState?: any; },
) {
  const navigate = useNavigate();

  function handleClick() {
    if (disabled) return;
    navigate(route, { state: paramState });
  }

  return (
    <Stack
      h={'68px'}
      w={'68px'}
      justify='center'
      align='center'
      gap={0}
      className={classes.tabsMenuButton}
      style={{ color: disabled ? 'gray' : 'initial' }}
      onClick={handleClick}
      data-active={route === location ? 'true' : 'false'}
    >
      {children}
    </Stack>
  );
}
