import { useGetDemoMessages } from '@/services/api/demo/chat';
import AppState from '@/services/state/AppState';
import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { Capacitor } from '@capacitor/core';
import { Group, Stack } from '@mantine/core';
import { useTour } from '@reactour/tour';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChatInput from './ChatInput';
import MessagesList, { MessagesListRef } from './MessagesList';

const getResponseMessage = nowDate => ({
  'id': '01J804V5PS6D4BB0NM1BVKHW22',
  'text': 'This is a demo account, there is no personnel assigned to it',
  'createdAt': nowDate,
  'updatedAt': nowDate,
  'sentAt': nowDate,
  'readAt': nowDate,
  'createdBy': MESSAGE_AUTHOR.MB_PERSONNEL,
  'createdByIdentity': 'Personnel',
  'metadata': null,
  'type': 'TEXT',
});

const DemoChat = ({ styles }) => {
  const defaultMessages = [{
    'id': '01J7KQYZ9FJ9NS3TKFNEM3XVYB',
    'text': '',
    'createdAt': '2024-09-12T18:29:37.709Z',
    'updatedAt': '2024-09-12T18:29:37.709Z',
    'sentAt': '2024-09-12T18:29:37.676Z',
    'readAt': null,
    'createdBy': 'MB_SYSTEM',
    'createdByIdentity': 'MailBerry',
    'metadata': { 'name': 'The Nike Cortez:Vintage x Modern', 'draftId': '01J800XBAMJ94SJKDRPQ40780M', 'openRate': 42.4, 'clickRate': 2.4, 'minutesAfterSent': 240 },
    'type': 'STATS',
    'authId': '01J0S0B7YV66EYNN85N630N3QH',
  }, {
    'id': '01J7KQYZ9FJ9NS3TKFNEM3XVYB',
    'text': 'Draft with name The Nike Cortez:Vintage x Modern has been updated.',
    'createdAt': '2024-09-12T18:29:37.709Z',
    'updatedAt': '2024-09-12T18:29:37.709Z',
    'sentAt': '2024-09-12T18:29:37.676Z',
    'readAt': null,
    'createdBy': 'MB_SYSTEM',
    'createdByIdentity': 'MailBerry',
    'metadata': { 'draftId': '01J7KQYZ3Q8MY29G178K40MQAS', 'category': 'DRAFT_CREATED' },
    'type': 'PILL',
    'authId': '01J0S0B7YV66EYNN85N630N3QH',
  }, {
    'id': '01J7KQYZ9FJ9NS3TKFNEM3XVYB',
    'text': 'Draft with name The Nike Cortez:Vintage x Modern has been created.',
    'createdAt': '2024-09-12T18:29:37.709Z',
    'updatedAt': '2024-09-12T18:29:37.709Z',
    'sentAt': '2024-09-12T18:29:37.676Z',
    'readAt': null,
    'createdBy': 'MB_SYSTEM',
    'createdByIdentity': 'MailBerry',
    'metadata': { 'draftId': '01J7KQYZ3Q8MY29G178K40MQAS', 'category': 'DRAFT_CREATED' },
    'type': 'PILL',
    'authId': '01J0S0B7YV66EYNN85N630N3QH',
  }, {
    'id': '01J7KQYZ9FJ9NS3TKFNEM3XVYB',
    'text': '',
    'createdAt': '2024-09-12T18:29:37.709Z',
    'updatedAt': '2024-09-12T18:29:37.709Z',
    'sentAt': '2024-09-12T18:29:37.676Z',
    'readAt': null,
    'createdBy': 'MB_SYSTEM',
    'createdByIdentity': 'MailBerry',
    'metadata': { 'name': 'The Nike Vomero 17 has arrived', 'draftId': '01J800XBAMJ94SJKDRPQ40780M', 'openRate': 45.2, 'clickRate': 2.8, 'minutesAfterSent': 240 },
    'type': 'STATS',
    'authId': '01J0S0B7YV66EYNN85N630N3QH',
  }, {
    'id': '01J7KQYZ9FJ9NS3TKFNEM3XVYB',
    'text': 'Draft with name The Nike Vomero 17 has arrived has been updated.',
    'createdAt': '2024-09-12T18:29:37.709Z',
    'updatedAt': '2024-09-12T18:29:37.709Z',
    'sentAt': '2024-09-12T18:29:37.676Z',
    'readAt': null,
    'createdBy': 'MB_SYSTEM',
    'createdByIdentity': 'MailBerry',
    'metadata': { 'draftId': '01J7KQYZ3Q8MY29G178K40MQAS', 'category': 'DRAFT_CREATED' },
    'type': 'PILL',
    'authId': '01J0S0B7YV66EYNN85N630N3QH',
  }, {
    'id': '01J7KQYZ9FJ9NS3TKFNEM3XVYB',
    'text': 'Draft with name The Nike Vomero 17 has arrived has been created.',
    'createdAt': '2024-09-12T18:29:37.709Z',
    'updatedAt': '2024-09-12T18:29:37.709Z',
    'sentAt': '2024-09-12T18:29:37.676Z',
    'readAt': null,
    'createdBy': 'MB_SYSTEM',
    'createdByIdentity': 'MailBerry',
    'metadata': { 'draftId': '01J7KQYZ3Q8MY29G178K40MQAS', 'category': 'DRAFT_CREATED' },
    'type': 'PILL',
    'authId': '01J0S0B7YV66EYNN85N630N3QH',
  }];
  const messagesListTopRef = useRef<MessagesListRef>(null);

  const { setIsOpen, currentStep, setCurrentStep, isOpen } = useTour();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [localMessages, setlocalMessages] = useState(defaultMessages);

  useEffect(() => {
    console.log('CHAT', currentStep);
    if (currentStep === 7) {
      setCurrentStep(8);
      setIsOpen(true);
    }
  }, [currentStep]);

  const handleClickSend = v => {
    if (isOpen && currentStep === 9) {
      setIsOpen(false);
    }
    const nowDate = new Date().toISOString();
    // @ts-ignore
    setlocalMessages(prev => {
      return [{
        'id': '01J804V5PS6D4BB0NM1BVKHW2P',
        'text': v,
        'createdAt': nowDate,
        'updatedAt': nowDate,
        'sentAt': nowDate,
        'readAt': nowDate,
        'createdBy': MESSAGE_AUTHOR.MANAGED_USER,
        'createdByIdentity': 'CustomerDemo',
        'metadata': null,
        'type': 'TEXT',
      }, ...prev];
    });

    setTimeout(() => {
      // @ts-ignore
      setlocalMessages(prev => [getResponseMessage(nowDate), ...prev]);

      // if (currentStep === 9) {
      //   setTimeout(() => {
      //     navigate('/demo');
      //   }, 1500);
      // }
    }, 1000);
  };

  return (
    <Stack
      style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        ...styles,
      }}
      gap={0}
      bg={'#F4F1EB'}
    >
      <MessagesList
        showNewMessages={false}
        newMessagesLoading={false}
        newMessages={localMessages}
        readMessages={[]}
        isMobile={true}
        isLoading={false}
        isFetchingNextPage={false}
        ref={messagesListTopRef}
      />
      <Group style={{ flexShrink: 0 }} w={'100%'} justify='center'>
        <ChatInput
          onSend={handleClickSend}
          moveMessagesToRead={(sentMessage: ChatMessage, messagesToMove?: ChatMessage[]) => {}}
          sendingOneMsgAtm={false}
          authId={null}
          isMobile={true}
        />
      </Group>
    </Stack>
  );
};

export default DemoChat;
