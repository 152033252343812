import { genericAuthRequest } from '@/services/api/base';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { GetTokenOptions } from 'firebase/messaging';

import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
let log = console.log.bind(console);

export const initNotifications = async (accessToken: string, actionPerformedListenerFn: (PushNotificationSchema) => void) => {
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', async (token: Token) => {
    // save token in db
    log('registration');
    await upsertSubscriptionOnBackend(accessToken, token.value);
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error: any) => {
    log('registrationError');
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
    log('pushNotificationReceived');
  });

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
    log('pushNotificationActionPerformed', action);
    actionPerformedListenerFn(action.notification);
  });
};

// Deletes firebase token on Android and unregister APNS on iOS
export const stopNotifications = async (accessToken: string) => {
  PushNotifications.unregister();
  await unsubscribeUserFromPushService(accessToken);
};

const unsubscribeUserFromPushService = async (accessToken: string) => {
  try {
    log('DELETE FROM DB .User is not subscribed');
    await genericAuthRequest(accessToken, 'DELETE', '/notifications');
  } catch (error) {
    log('Update Subscription failed', error);
  }
};

const upsertSubscriptionOnBackend = async (
  accessToken: string,
  token: string,
) => {
  try {
    await genericAuthRequest(accessToken, 'POST', '/notifications', { subscription: token });
  } catch (error) {
    log('Update Subscription failed', error);
  }
};
