import TabMenuButton from '@components/screens/TabsMenu/TabsMenuButton';
import { ColorSwatch, Group, Text } from '@mantine/core';
import React from 'react';
import { FaChartBar } from 'react-icons/fa';
import { FiBookOpen } from 'react-icons/fi';
import { TiMessages } from 'react-icons/ti';
import { useLocation } from 'react-router-dom';

export default function DemoTabsMenu() {
  let location = useLocation();
  const haveDemoMessage = localStorage.getItem('haveDemoMessage');

  return (
    <Group
      align='center'
      // justify={appState.installed.platform === 'ios' ? 'flex-end' : 'center'}
      justify={'center'}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      <TabMenuButton route={'/demo'} location={location.pathname}>
        <FiBookOpen size={24} />
        <Text>Overview</Text>
      </TabMenuButton>
      <TabMenuButton route={'/demo/chat'} location={location.pathname}>
        {(haveDemoMessage && haveDemoMessage === '1')
          && (
            <ColorSwatch
              color='red'
              style={{
                top: '20%',
                right: '10%',
                transform: 'translateY(-50%)',
                aspectRatio: 1,
              }}
              size={'1.75em'}
            >
              <Text c='white' size='lg' fw='bolder'>{1}</Text>
            </ColorSwatch>
          )}

        <TiMessages size={24} />
        <Text>Chat</Text>
      </TabMenuButton>
      <TabMenuButton route={'/demo/reports'} location={location.pathname}>
        <FaChartBar size={24} />
        <Text>Reports</Text>
      </TabMenuButton>
    </Group>
  );
}
