import Wrapper from '@/components/Wrapper';
import { formatDate, formatTime, formatTimeAndDate } from '@/lib/utils';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { MESSAGE_TYPE } from '@/sharedTypes';
import { Box, Group, Stack, Text } from '@mantine/core';
import { EnvelopeOpenIcon, Pencil2Icon } from '@radix-ui/react-icons';
import { useState } from 'react';
import MessageReview from './MessageReview';
import MessageStats from './MessageStats';
import { MetadataStats } from './MessageStats';

interface MessageBoxProps {
  message: string;
  time: string;
  isSender: boolean;
  type: MESSAGE_TYPE;
  metadata?: Record<any, any>;
}

const MessageBox = ({ message, time, isSender, type, metadata }: MessageBoxProps) => {
  const date = formatDate(time);
  const formatPillMessage = message => {
    // Use regex to find the date placeholder
    return message.replace(/\{\{date:(.*?)\}\}/, (match, p1) => {
      if (p1) {
        return formatTimeAndDate(p1);
      }
      return match;
    });
  };

  if (type === MESSAGE_TYPE.TEXT) {
    return (
      <Group justify={isSender ? 'flex-end' : 'flex-start'}>
        <Stack
          style={{
            minWidth: 32,
            maxWidth: '90%',
            borderTopRightRadius: '1em',
            borderTopLeftRadius: '1em',
            backgroundColor: isSender ? '#c7e6ff' : '#FFFFFF',
            borderBottomRightRadius: isSender ? 0 : '1em',
            borderBottomLeftRadius: isSender ? '1em' : 0,
            padding: '0.4em 0.6em 0em 0.6em',
            overflowWrap: 'break-word',
            whiteSpace: 'break-spaces',
            position: 'relative',
          }}
          gap={0}
        >
          <Text>{message}</Text>
          <Group justify={isSender ? 'flex-start' : 'flex-end'}>
            <Text size='xs' c='gray' style={{ right: 0, marginRight: 8, whiteSpace: 'nowrap' }}>
              {date}
            </Text>
          </Group>
        </Stack>
      </Group>
    );
  } else if (type === MESSAGE_TYPE.REVIEW) {
    return <MessageReview message={message} time={time} isSender={isSender} metadata={metadata} type={MESSAGE_TYPE.REVIEW} />;
  } else if (type === MESSAGE_TYPE.STATS) {
    return <MessageStats message={message} time={time} isSender={isSender} metadata={metadata} type={MESSAGE_TYPE.STATS} />;
  } else if (type === MESSAGE_TYPE.PILL) {
    // find replacements between brackets
    const messageFormated = formatPillMessage(message);
    return (
      <Group justify='center' style={{ margin: '3px 0' }}>
        <Group
          style={{
            borderRadius: '1em',
            backgroundColor: '#D3D3D3',
            padding: '3px 8px',
            display: 'inline-block',
            maxWidth: '70%',
          }}
          justify='center'
        >
          <Text
            style={{
              fontSize: '14px',
              color: '#4A4A4A',
            }}
            ta={'center'}
          >
            {messageFormated}
          </Text>
        </Group>
      </Group>
    );
  }

  return null;
};

export default MessageBox;
