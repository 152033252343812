import TabMenuButton from '@components/screens/TabsMenu/TabsMenuButton';
import { Box, ColorSwatch, Group, Text } from '@mantine/core';
import { useGetUnreadMessages } from '@services/api/messages';
import { FaChartBar } from 'react-icons/fa';
import { FiBookOpen } from 'react-icons/fi';
import { TiMessages } from 'react-icons/ti';
import { useLocation } from 'react-router-dom';

export default function TabsMenu() {
  let location = useLocation();
  const { data: unreadMessages } = useGetUnreadMessages({ enabled: location.pathname !== '/chat' });

  return (
    <Group
      align='center'
      // justify={appState.installed.platform === 'ios' ? 'flex-end' : 'center'}
      justify={'center'}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      <TabMenuButton route={'/'} location={location.pathname}>
        <FiBookOpen size={24} />
        <Text>Overview</Text>
      </TabMenuButton>
      <TabMenuButton route={'/chat'} location={location.pathname} paramState={{ newMessages: !!unreadMessages?.length }}>
        {unreadMessages && unreadMessages.length > 0 && (
          <Box style={{ position: 'relative' }}>
            <ColorSwatch
              color='red'
              style={{
                position: 'absolute',
                top: '20%',
                right: '10%',
                transform: 'translateY(-50%)',
                aspectRatio: 1,
              }}
              size={'1.75em'}
            >
              <Text c='white' size='lg' fw='bolder'>{unreadMessages.length}</Text>
            </ColorSwatch>
          </Box>
        )}
        <TiMessages size={24} />
        <Text>Chat</Text>
      </TabMenuButton>
      <TabMenuButton route={'/reports'} location={location.pathname}>
        <FaChartBar size={24} />
        <Text>Reports</Text>
      </TabMenuButton>
    </Group>
  );
}
