import { DEFAULT_BUSINESS_ADDRESS } from '@/lib/constants';
import { Text } from '@mantine/core';

export function EmailPreviewFooter({ businessMailAddress }: { businessMailAddress: string; }) {
  return (
    <>
      <div style={{ fontSize: '0.875rem', textAlign: 'center', padding: '32px 48px' }}>
        <span>
          {businessMailAddress}
        </span>
        <br />
        No longer want to receive these emails?{' '}
        <span style={{ color: '#3B82F6', textDecoration: 'underline' }}>
          Unsubscribe
        </span>
      </div>
    </>
  );
}

export function EmailAddressAndUnsubscribe({ businessMailAddress, styles }) {
  return (
    <div className='footer' style={{ color: styles?.fontColor ? styles.fontColor : '#000000' }}>
      <span style={{ color: '#0F69FF', textDecoration: 'underline' }}>{(businessMailAddress?.length > 0 && businessMailAddress) || DEFAULT_BUSINESS_ADDRESS}</span>
      <br />
      No longer want to receive these emails? <a>Unsubscribe</a>
    </div>
  );
}

export function EmailCreatedWithMailberry({ styles }) {
  return (
    <div className='footer' style={{ color: styles?.fontColor ? styles.fontColor : '#000000' }}>
      Powered by{' '}
      <a target={'_blank'} rel='noreferrer' href='https://mailberry.ai'>
        <img
          width='75'
          height='18'
          style={{ width: 75, height: 18, verticalAlign: 'text-bottom', display: 'inline-block', marginLeft: 5 }}
          src='https://d1q3o19jn6nxkf.cloudfront.net/email/mailberry-logo.png'
          alt='Mailberry logo'
        />
      </a>
    </div>
  );
}
