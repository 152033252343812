import { calcAverage } from '@/lib/utils';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { Button, Card, Divider, Group, Progress, Skeleton, Text } from '@mantine/core';
import React from 'react';

const ReportList = ({ isLoading, isLoadingDeliveries, drafts, deliveries, onView }) => {
  const calculateRates = draftId => {
    const delivery = deliveries.find(del => del.draftId === draftId);
    if (!delivery) return { clickRate: 0, openRate: 0 };
    const clickRate = calcAverage(delivery.open, delivery.click);
    const openRate = calcAverage(delivery.sendTo, delivery.open);
    return { clickRate, openRate };
  };

  return (
    <>
      {isLoading || isLoadingDeliveries
        ? (
          Array.from({ length: 5 }).map((_, index) => (
            <Card key={index} shadow='sm' p='lg' radius='md' withBorder>
              <Skeleton height={30} width='70%' mb='md' />
              <Skeleton height={20} width='90%' mb='md' />
              <Divider />
              <Skeleton height={20} width='50%' mb='md' />
              <Skeleton height={20} radius='md' width='100%' />
              <Skeleton height={20} width='50%' mt='md' />
              <Skeleton height={20} radius='md' width='100%' />
            </Card>
          ))
        )
        : drafts?.length === 0
        ? <MBEmptyGeneric />
        : (
          drafts?.map(draft => {
            const { clickRate, openRate } = calculateRates(draft.id);
            return (
              <Card key={draft.id} shadow='sm' p='lg' radius='md' withBorder>
                <Text fw={500} size='lg' lineClamp={2}>{draft.subject}</Text>
                <Text size='sm' c='dimmed'>{draft.preHeader}</Text>
                <Text size='sm' c='dimmed' mt='sm'>
                  {`Sent on ${
                    new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    }).format(new Date(draft.delivery.toBeSentAt))
                  }`}
                </Text>
                <Divider mt='sm' mb='md' />
                <Text size='sm' c='green' style={{ marginBottom: 4, marginTop: 12 }}>{`Open rate: ${openRate.toFixed(2)}%`}</Text>
                <Progress color='green' value={openRate} />
                <Text size='sm' c='blue' style={{ marginBottom: 4 }}>{`Click rate: ${clickRate.toFixed(2)}%`}</Text>
                <Progress color='blue' value={clickRate} />
                <Group justify='right' mt='md'>
                  <Button variant='outline' onClick={() => onView({ draftId: draft.id })}>View Details</Button>
                </Group>
              </Card>
            );
          })
        )}
    </>
  );
};

export default ReportList;
